import React, { Component } from "react";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { withTranslation, WithTranslation } from "react-i18next";
import {
    Container,
    Theme,
    Typography,
    withStyles,
    WithStyles,
    createStyles,
    List,
    ListItem,
    Fade,
} from "@material-ui/core/";

import { fetchImageWithAccessToken } from "src/utils";
import { apiUrl } from "src/settings";
import { Site, SiteDetails } from "src/entities";

import { SiteOverviewVideoStream } from "./SiteOverviewVideoStream";
import { SiteOverviewConfigurationGroup } from "./SiteOverviewConfigurationGroup";
import "./_configuration-groups.scss";
import "./_site-overview.scss";
import "./_video-stream-list.scss";

const themedStyle = (theme: Theme) =>
    createStyles({
        siteOverviewListPrimary: {
            color: theme.palette.text.secondary,
        },
        siteOverviewListSecondary: {
            color: theme.palette.text.primary,
        },
        siteOverviewListSpacer: {
            background: theme.palette.divider,
        },
    });

type OwnProps = {
    site: Site;
    siteDetails: SiteDetails;
    siteDetailsIsLoading: boolean;
    theme: Theme;
};

type Props = OwnProps & WithStyles<typeof themedStyle> & RouteComponentProps & WithTranslation;

class SiteOverviewInternal extends Component<Props, any> {
    constructor(props) {
        super(props);

        this.state = {
            imageUrl: "",
        };
    }
    componentDidMount() {
        const sub = fetchImageWithAccessToken(`${apiUrl}/sites/${this.props.site.id}/map/image.jpg`).subscribe(
            (response) => {
                sub.unsubscribe();
                this.setState((state) => ({
                    ...state,
                    imageUrl: response.url,
                }));
            },
        );
    }

    render() {
        const { classes, t } = this.props;
        return (
            <div className="site-overview">
                {this.props.site ? (
                    <React.Fragment>
                        <div className="sites-card">
                            <Fade in={true} timeout={{ enter: 150 }}>
                                <div className="site-overview__tab">
                                    <Container>
                                        <img
                                            src={this.state.imageUrl}
                                            alt={this.props.site.name}
                                            className="site-overview__map"
                                        />
                                    </Container>
                                    <List dense={true} disablePadding={true} className="site-overview__list">
                                        <ListItem disableGutters={true}>
                                            <Typography variant="subtitle1">{this.props.site.name}</Typography>
                                        </ListItem>
                                        <ListItem disableGutters={true}>
                                            <div
                                                className={`site-overview__list-item site-overview__list-item--primary ${classes.siteOverviewListPrimary}`}
                                            >
                                                {t("Entities.TimeZone.TimeZone")}
                                            </div>
                                            <div
                                                className={`site-overview__list-item site-overview__list-item--spacer ${classes.siteOverviewListSpacer}`}
                                            ></div>
                                            <div
                                                className={`site-overview__list-item site-overview__list-item--secondary ${classes.siteOverviewListSecondary}`}
                                            >
                                                {this.props.site.timeZone}
                                            </div>
                                        </ListItem>
                                    </List>
                                </div>
                            </Fade>
                        </div>
                        {!this.props.siteDetailsIsLoading && this.props.siteDetails ? (
                            <React.Fragment>
                                <h3 className="title title--h3">{t("Entities.AlarmHandling.CamerasOnSite")}</h3>
                                <div className="video-stream-list">
                                    {Object.values(this.props.siteDetails.videoStreams).map((videoStream: any) => {
                                        const { functionalities } = videoStream;
                                        if (videoStream) {
                                            return (
                                                <SiteOverviewVideoStream
                                                    key={`${videoStream.id}`}
                                                    videoStream={videoStream}
                                                    functionalities={functionalities}
                                                    timeZone={this.props.site.timeZone}
                                                />
                                            );
                                        }
                                        return null;
                                    })}
                                </div>

                                <h3 className="title title--h3">
                                    {t("Entities.ConfigurationGroup.ConfigurationGroups")}
                                </h3>
                                <div className="configuration-groups">
                                    {Object.values(this.props.siteDetails.configurationGroups)?.map(
                                        (configurationGroup: any) => {
                                            if (configurationGroup) {
                                                return (
                                                    <SiteOverviewConfigurationGroup
                                                        key={`${configurationGroup.id}`}
                                                        configurationGroup={configurationGroup}
                                                    />
                                                );
                                            }
                                            return null;
                                        },
                                    )}
                                </div>
                            </React.Fragment>
                        ) : null}
                    </React.Fragment>
                ) : null}
            </div>
        );
    }
}

export const SiteOverview = withTranslation()(
    withStyles(themedStyle, { withTheme: true })(withRouter(SiteOverviewInternal)),
);
