import jwtDecode from "jwt-decode";
import moment from "moment-timezone";
import { UserProfile } from "src/entities";
import { apiUrl } from "src/settings";
import { LoginDetails, LoginRequest, Profile, TokenResponse, TwoFactorAuthMode } from "src/types";
import { fetchWithIntercept } from "./fetchService";

type RawToken = {
    nbf: number;
    exp: number;
    iss: string;
    aud: string[];
    client_id: string;
    sub: string;
    auth_time: number;
    idp: string;
    email: string;
    scope: string[];
    amr: string[];
};

export const updateUserProfile = async (patchDelta: Profile, logout: () => void) => {
    const url = `${apiUrl}/profile`;

    let response;

    try {
        response = await fetchWithIntercept(url, "PATCH", logout, patchDelta);
    } catch (error) {
        throw new Error(error as string);
    }

    if (!response.ok) {
        throw new Error(`Patch user failed with ${response.status}`);
    }

    const jsonResponse = await response.json();

    // Handle some proprietary error messages from API
    if (jsonResponse.errors) {
        let errorMessage = "";

        Object.entries(jsonResponse.errors).forEach(([, error]) => {
            errorMessage = (error as Array<string>)[0];
        });

        throw new Error(errorMessage);
    }

    return jsonResponse;
};

export const activate2fa = async (email: string, logout: () => void) => {
    const twoFactorUrl = `${apiUrl}/account/twoFactorAuthentication/activate?email=${email}`;

    try {
        await fetchWithIntercept(twoFactorUrl, "GET", logout);
    } catch (error) {
        throw new Error(`Activate 2fa error: ${error}`);
    }
};

export const checkTwoFactorStatus = async (email: string, logout: () => void): Promise<boolean> => {
    const url = `${apiUrl}/account/twoFactorAuthMode?email=${email}`;

    try {
        const response = await fetchWithIntercept(url, "GET", logout);
        const twoFactorResponse = await response.json();

        let userRequiresTwoFactor = false;

        const userAuthMode: TwoFactorAuthMode = twoFactorResponse;
        if (userAuthMode === TwoFactorAuthMode.GoogleAuthenticator) {
            userRequiresTwoFactor = true;
        }

        return userRequiresTwoFactor;
    } catch (error) {
        throw new Error(`Check 2fa error: ${error}`);
    }
};

export const fetchUserProfile = async (logout: () => void) => {
    const url = `${apiUrl}/profile`;

    let response: Response;

    try {
        response = await fetchWithIntercept(url, "GET", logout);
    } catch (error) {
        throw new Error(`network error`);
    }

    if (!response.ok) {
        throw new Error(`getUserProfile failed with status ${response.status}`);
    }

    const profileResponse = (await response.json()) as UserProfile;
    return profileResponse;
};

export const fetchToken = async (credentials: LoginRequest): Promise<LoginDetails> => {
    const url = `${apiUrl}/token`;

    try {
        const response = await fetch(url, {
            method: "POST",
            headers: {
                "Content-Type": "application/json;charset=UTF-8",
            },
            body: JSON.stringify(credentials),
        });

        if (!response.ok) {
            throw new Error("Login failed");
        }

        const tokenResponse = (await response.json()) as TokenResponse;
        const rawToken = jwtDecode<RawToken>(tokenResponse.accessToken);

        const token: LoginDetails = {
            ...tokenResponse,
            accessTokenExpiresAt: moment.unix(rawToken.exp),
            email: rawToken.email,
        };
        return token;
    } catch (error) {
        throw new Error(`requestGetToken fails ${error}`);
    }
};
