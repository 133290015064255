import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronDown } from "@fortawesome/pro-regular-svg-icons";
import { ConfigurationGroup } from "src/entities";
import cx from "classnames";

import { Schedule } from "./Schedule";
import { Instructions } from "./Instructions";

type SiteOverviewConfigurationGroupProps = {
    configurationGroup: ConfigurationGroup;
};

export const SiteOverviewConfigurationGroup = (props: SiteOverviewConfigurationGroupProps) => {
    const { t } = useTranslation();
    const { configurationGroup } = props;
    const [expanded, setExpanded] = useState(false);

    return (
        <div className="configuration-groups__item-wrapper">
            <div
                className={cx([
                    "card",
                    "configuration-groups__item",
                    {
                        "card--alt": expanded,
                        "configuration-groups__item--expanded": expanded,
                    },
                ])}
            >
                <div className="configuration-groups__header">
                    <div
                        className="configuration-groups__color-indicator"
                        style={{ backgroundColor: configurationGroup.hexColor || "transparent" }}
                    />

                    <p className="paragraph paragraph--bold paragraph--tiny-spacing">
                        {configurationGroup.name
                            ? configurationGroup.name
                            : t(`Entities.Default.DefaultConfigurationName`, {
                                  type: t(`Enums.ConfigurationGroupType.${configurationGroup.type}`),
                                  index: configurationGroup.index,
                              })}
                    </p>

                    <p className="caption caption--extra-spacing">
                        {t(`Enums.ConfigurationGroupType.${configurationGroup.type}`)}
                    </p>
                </div>

                {expanded && configurationGroup.schedule ? <Schedule schedule={configurationGroup.schedule} /> : null}

                {expanded && configurationGroup.instruction ? (
                    <Instructions instruction={configurationGroup.instruction} />
                ) : null}

                <button
                    className="configuration-groups__expander"
                    onClick={() => {
                        setExpanded(!expanded);
                    }}
                >
                    <span className="button-text">Details</span>
                    <FontAwesomeIcon icon={faChevronDown} className="configuration-groups__expander-icon" />
                </button>
            </div>
        </div>
    );
};
