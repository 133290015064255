import React from "react";

import { Tab, TabProps } from "@material-ui/core";
import { Link, LinkProps } from "src/components";

const InternalLinkTab: React.ComponentType<LinkTabProps> = Tab as React.ComponentType<LinkTabProps>;

type LinkTabProps = LinkProps & TabProps;

export class LinkTab extends React.Component<LinkTabProps> {
    render() {
        return (
            <InternalLinkTab component={Link} {...this.props}>
                {this.props.children}
            </InternalLinkTab>
        );
    }
}
