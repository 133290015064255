import React from "react";

import { Link as MaterialLink, LinkProps as MaterialLinkProps } from "@material-ui/core";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

export type LinkProps = RouterLinkProps & MaterialLinkProps;

// eslint-disable-next-line react/display-name
const RouterLinkComponent = React.forwardRef<HTMLAnchorElement, RouterLinkProps>((props, ref) => (
    <RouterLink innerRef={ref} {...props} />
));

export class Link extends React.Component<LinkProps> {
    render() {
        return (
            <MaterialLink component={RouterLinkComponent} {...this.props}>
                {this.props.children}
            </MaterialLink>
        );
    }
}
