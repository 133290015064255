import React, { useState, useEffect, useRef } from "react";

import { fetchImageWithAccessToken } from "src/utils";

import "./_lazy-image.scss";

const placeHolder =
    "data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkqAcAAIUAgUW0RjgAAAAASUVORK5CYII=";

type OwnProps = {
    src: string;
    alt: string;
    intersectionNode: Element | null;
};

export const LazyLoadImage = (props: OwnProps) => {
    const [imageSrc, setImageSrc] = useState("");
    const [inViewport, setInViewport] = useState(false);
    const wrapperRef = useRef<HTMLDivElement>(null);

    const onLoad = (event) => {
        if (event.target.complete) {
            event.target.classList.add("lazy-image__source--loaded");
        }
    };

    const onError = (event) => {
        event.target.classList.add("lazy-image__source--has-error");
        setImageSrc(placeHolder);
    };

    useEffect(() => {
        let sub;
        if (inViewport) {
            sub = fetchImageWithAccessToken(`${props.src}`).subscribe((response) => {
                setImageSrc(response.url);
            });
        }

        return () => {
            if (sub) {
                sub.unsubscribe();
            }
        };
    }, [inViewport]);

    useEffect(() => {
        let observer;
        let didCancel = false;

        if (wrapperRef?.current) {
            if (IntersectionObserver) {
                observer = new IntersectionObserver(
                    (entries) => {
                        entries.forEach((entry) => {
                            if (!didCancel && (entry.intersectionRatio > 0 || entry.isIntersecting)) {
                                setInViewport(true);
                                observer.unobserve(wrapperRef?.current);
                            }
                        });
                    },
                    {
                        root: props.intersectionNode,
                        threshold: 0.01,
                        rootMargin: "75%",
                    },
                );
                observer.observe(wrapperRef?.current);
            } else {
                // Old browsers fallback
                setInViewport(true);
            }
        }
        return () => {
            didCancel = true;
            // on component cleanup, we remove the listener
            if (observer?.unobserve && wrapperRef?.current) {
                observer.unobserve(wrapperRef.current);
            }
        };
    }, [wrapperRef]);
    return (
        <div ref={wrapperRef} className="lazy-image">
            {inViewport && imageSrc ? (
                <img src={imageSrc} alt={props.alt} className="lazy-image__source" onLoad={onLoad} onError={onError} />
            ) : null}
        </div>
    );
};
