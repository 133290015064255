import React from "react";
import { Redirect, Route } from "react-router-dom";
import { setUserLastKnownRoute } from "src/utils/storage";
import { getToken } from "../../utils";

export const AuthenticatedRoute: React.FC<any> = (props: any) => {
    const token = getToken();

    if (token) {
        return <Route path={props.path} component={props.component} />;
    }

    setUserLastKnownRoute(props.location.pathname);
    return <Redirect to="/" />;
};
