import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGlobe, faLanguage } from "@fortawesome/pro-regular-svg-icons";
import { faPen, faUser } from "@fortawesome/pro-solid-svg-icons";
import { GetDataTransferStatus, IrMap, Language, Profile, SaveDataTransferStatus } from "src/types";
import { useDialogModes } from "src/hooks/useDialogModes";
import { UserProfileIntroDialog } from "./Dialogs/UserProfileIntroDialog/UserProfileIntroDialog";
import type { AvailableIntroTabs } from "./Types";

type UserProfileIntroProps = {
    profile: Profile;
    languages: Language[];
    locales: string[];
    timeZones: IrMap<string, string>;
    updateUser: (profile: Profile) => Promise<void>;
    saveProfileStatus: SaveDataTransferStatus;
    getLocalizationStatus: GetDataTransferStatus;
};

export const UserProfileIntro = (props: UserProfileIntroProps) => {
    const [introDialogIsOpen, setIntroDialogIsOpen] = useState(false);
    const [introDialogInitialTab, setIntroDialogInitialTab] = useState<AvailableIntroTabs>("DetailsTab");
    const [introDialogFormDirty, setIntroDialogFormDirty] = useState(false);

    const { t } = useTranslation();

    const { fullscreen } = useDialogModes();

    const handleEditIntroButtonClick = (initialTab: AvailableIntroTabs) => {
        setIntroDialogInitialTab(initialTab);
        setIntroDialogIsOpen(true);
    };

    const handleIntroDialogFormDirty = (isDirty: boolean) => {
        setIntroDialogFormDirty(isDirty);
    };

    const handleIntroDialogClose = (event?: any, reason?: "backdropClick" | "escapeKeyDown" | "userCancelled") => {
        if (introDialogFormDirty && reason !== "userCancelled") {
            return;
        }

        setIntroDialogIsOpen(false);
    };

    return (
        <div data-testid="user-profile-intro">
            <div className="card">
                <div className="data-list data-list--extra-spacing user-profile-details__bottom-separator">
                    <div className="data-list__header data-list__header--extra-spacing user-profile-details__header-with-edit">
                        <FontAwesomeIcon icon={faUser} />
                        <div className="user-profile-details__header-text">
                            <h3 className="title title--h3 title--no-spacing">
                                {props.profile?.fullName
                                    ? props.profile.fullName
                                    : t("Gui.Common.UserProfileIntro.MissingFullName")}
                            </h3>
                            <p className="caption caption--no-spacing">
                                {props.profile?.displayName
                                    ? props.profile.displayName
                                    : t("Gui.Common.UserProfileIntro.MissingDisplayName")}
                            </p>
                        </div>
                        <IconButton
                            data-testid="upper-edit-button"
                            className="icon-button"
                            onClick={() => handleEditIntroButtonClick("DetailsTab")}
                        >
                            <FontAwesomeIcon className="icon-button__icon" icon={faPen} />
                        </IconButton>
                    </div>
                    <div className="data-panels data-panels--rows">
                        <div className="data-panels__panel data-panels__panel--extra-spacing">
                            <div className="data-panels__heading">
                                <p className="caption">{t("Gui.Common.UserProfileIntro.EmailHeader")}</p>
                            </div>
                            <div className="data-panels__heading">
                                <p className="paragraph paragraph--small paragraph--bold">{props.profile?.email}</p>
                            </div>
                        </div>
                    </div>
                    <div className="data-panels data-panels--rows">
                        <div className="data-panels__panel data-panels__panel--extra-spacing">
                            <div className="data-panels__heading">
                                <p className="caption">{t("Gui.Common.UserProfileIntro.PhoneHeader")}</p>
                            </div>
                            <div className="data-panels__heading">
                                <p className="paragraph paragraph--small paragraph--bold">
                                    {props.profile?.phoneNumber
                                        ? props.profile.phoneNumber
                                        : t("Gui.Common.UserProfileIntro.MissingPhoneNumber")}
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="data-panels data-panels--rows">
                        <div className="data-panels__panel data-panels__panel--extra-spacing">
                            <div className="data-panels__heading">
                                <p className="caption">{t("Gui.Common.UserProfileIntro.SmsHeader")}</p>
                            </div>
                            <div className="data-panels__heading">
                                <p className="paragraph paragraph--small paragraph--bold">
                                    {props.profile?.smsNumber
                                        ? props.profile.smsNumber
                                        : t("Gui.Common.UserProfileIntro.MissingSmsNumber")}
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="data-list data-list--extra-spacing">
                    <div data-testid="language-section" className="user-profile-details__language-section">
                        <div className="user-profile-details__language-section--row">
                            <FontAwesomeIcon icon={faGlobe} />
                            <p className="caption caption--no-spacing">
                                {t(`Entities.TimeZone.${props.profile ? props.profile.timeZone?.name : ""}`)}
                            </p>
                        </div>
                        <div className="user-profile-details__language-section--row">
                            <FontAwesomeIcon icon={faLanguage} />
                            <div className="user-profile-details__header-text">
                                <p className="caption caption--no-spacing">
                                    {t(`Entities.Language.${props.profile ? props.profile.language?.key : ""}`)}
                                </p>
                                <p className="caption caption--no-spacing">
                                    {t(`Entities.Locale.${props.profile ? props.profile?.locale : ""}`)}
                                </p>
                            </div>
                        </div>
                        <IconButton
                            data-testid="lower-edit-button"
                            className="icon-button"
                            onClick={() => handleEditIntroButtonClick("LocaleTab")}
                        >
                            <FontAwesomeIcon className="icon-button__icon" icon={faPen} />
                        </IconButton>
                    </div>
                </div>
                <Dialog
                    open={introDialogIsOpen}
                    fullScreen={fullscreen}
                    fullWidth
                    maxWidth="md"
                    onClose={handleIntroDialogClose}
                >
                    <UserProfileIntroDialog
                        profile={props.profile}
                        languages={props.languages}
                        locales={props.locales}
                        timeZones={props.timeZones}
                        updateUser={props.updateUser}
                        saveProfileStatus={props.saveProfileStatus}
                        getLocalizationStatus={props.getLocalizationStatus}
                        initialTab={introDialogInitialTab}
                        onChange={handleIntroDialogFormDirty}
                        onClose={handleIntroDialogClose}
                    />
                </Dialog>
            </div>
        </div>
    );
};
