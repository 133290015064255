import React from "react";
import { useTranslation } from "react-i18next";
import { Container } from "@material-ui/core/";
import { Link, LogoAnimation } from "src/components";

import "./About.scss";
import "../Login.scss";

export const About = () => {
    const { t } = useTranslation();
    return (
        <div className="login-page">
            <div className="login-page__wide-container">
                <div className="login-page__wide-background" />
                <div className="login-page__panel">
                    <div className="about-page">
                        <Container maxWidth="md">
                            <LogoAnimation />
                            <div className="text-container">
                                <div className="lead text-center">
                                    Meet the smartest, most curious, most exact
                                    <br /> trouble spotter in the surveillance business.
                                    <br /> (Yes, it’s a machine, a very intelligent machine.)
                                </div>

                                <p>
                                    How many times have you heard people blaming ‘the human factor?’ To err is human, as
                                    they say. But in the security business, the goal is a zero fail rate, something
                                    nobody comes closer to than Irisity.
                                </p>

                                <div>
                                    <p>
                                        Basic problem: data overload. The poor humans whose job it is to monitor a
                                        typical large CCTV system are exposed to 4,000,000,000,000! pixels over 24
                                        hours. Four trillion!
                                    </p>

                                    <p>
                                        How on earth do you spot all human activity, i.e. all the bad guys, in that
                                        information torrent? And only the bad guys, so you don’t sound a false alarm
                                        because of shadows or moving curtains or animals or what have you?
                                    </p>

                                    <p>
                                        This is the problem Irisity has solved. And the solution is based on Artificial
                                        Intelligence and Machine Learning coupled with vigilant human input.
                                    </p>

                                    <p>
                                        With Irisity, nobody sits and stares day-long at a screen where nothing is
                                        happening. But when a camera does detect movement, an eight-second sequence is
                                        shown to the operator. He or she then decides whether it’s human activity or
                                        not, and let’s the system know. And sounds the alarm, if called for.
                                    </p>

                                    <p>
                                        Too simplistic? Let’s get back to Artificial Intelligence and Machine Learning.
                                        The “thinking part” of our system comprises nine patented algorithms (with four
                                        more pending). So far we’ve invested sixty million SEK into these algorithms,
                                        and the work continues.
                                    </p>

                                    <p>
                                        Every single time an operator determines that there’s human activity in the
                                        camera sequence, these specific pixel combinations are also added to the
                                        knowledge bank. Thus, the system simply keeps getting smarter and smarter and
                                        learns to detect and pass on only what’s interesting in any given picture. Which
                                        means the operator only has to deal with truly confirmed human activity.
                                    </p>

                                    <p>
                                        Irisity has been doing this in Scandinavia for ten years. For more than 350
                                        companies. The information that has been processed by our algorithms to date and
                                        incorporated into our proprietary knowledge bank is vast. And while you read
                                        this, it grew yet another bit.
                                    </p>
                                </div>
                            </div>

                            <Link to="/login" className="back-to-login">
                                <i className="fal fa-angle-left back-icon pr-2" />
                                {t("BeforeLogin.About.BackToLoginPage")}
                            </Link>
                        </Container>
                    </div>
                </div>
            </div>
        </div>
    );
};
