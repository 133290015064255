import i18n from "i18next";
import Backend from "i18next-xhr-backend";
import { initReactI18next } from "react-i18next";
import { apiUrl } from "../settings";

export const initLanguageHandling = (userLanguage: string) => {
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    i18n.use(initReactI18next)
        .use(Backend)
        .init({
            lng: userLanguage,
            keySeparator: false,
            interpolation: {
                escapeValue: false,
            },
            backend: {
                loadPath: `${apiUrl}/translations?lang={{lng}}`,
            },
            // debug: true,
        });
};

export const changeLanguage = (languageKey: string) => {
    return i18n.changeLanguage(languageKey);
};
