import React from "react";

import { Container } from "@material-ui/core";

import { Switch, Route } from "react-router-dom";
import { Dashboard, Login, NavBar, SiteDetails, About, AuthenticatedRoute, RedirectRoute } from "src/components";
import { getUserLastKnownRoute } from "src/utils/storage";
import { UserProfileDetails } from "./UserProfileDetails";

export const App: React.FC = () => {
    const redirectRoute: string = getUserLastKnownRoute() || "/dashboard";
    return (
        <React.Fragment>
            <NavBar />
            <Container>
                <Switch>
                    <AuthenticatedRoute path="/dashboard" component={Dashboard} />
                    <AuthenticatedRoute path="/sites/:id" component={SiteDetails} />
                    <AuthenticatedRoute path="/userProfile" component={UserProfileDetails} />
                    <Route path="/about" component={About} />
                    <RedirectRoute path="/" component={Login} redirectIfLoggedIn={redirectRoute} />
                </Switch>
            </Container>
        </React.Fragment>
    );
};
