import { Table, TableRow, TableCell, Button, Typography, TableHead, TableBody } from "@material-ui/core/";
import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash, faLowVision } from "@fortawesome/pro-solid-svg-icons";
import { faTimes, faCircleNotch } from "@fortawesome/pro-light-svg-icons";
import moment from "moment-timezone";
import { formatDateTime } from "../../../utils";
import { ScheduleException, Site } from "../../../entities";

type ScheduleExceptionsTableProps = {
    analysisEnabledCaption: string;
    onRemove?: (exception: ScheduleException) => void;
    removeButtonCaption?: string;
    removeRequestLoading?: boolean;
    scheduleExceptions: ScheduleException[];
    site: Site;
    disableRemoveButton: boolean;
};

export const ScheduleExceptionsTable = (props: ScheduleExceptionsTableProps) => {
    const { t } = useTranslation();
    const {
        analysisEnabledCaption,
        removeButtonCaption,
        removeRequestLoading,
        scheduleExceptions,
        site,
        disableRemoveButton,
    } = props;

    const [clicked, setClicked] = useState([] as string[]);

    const handleRemove = (ex: ScheduleException) => {
        setClicked([ex.id, ...clicked]);

        if (props.onRemove) {
            props.onRemove(ex);
        }
    };

    useEffect(() => {
        if (!removeRequestLoading) {
            setClicked([]);
        }
    }, [removeRequestLoading]);

    return (
        <>
            {scheduleExceptions?.length === 0 ? (
                <Typography variant="body2">{t("Entities.Schedule.NoScheduleExceptions")}</Typography>
            ) : null}
            {!!scheduleExceptions && scheduleExceptions.length !== 0 ? (
                <Table>
                    <TableHead className="site-exceptions__table-header">
                        <TableRow>
                            <TableCell>{analysisEnabledCaption}</TableCell>
                            <TableCell>{t("Entities.Schedule.StartTime")}</TableCell>
                            <TableCell>{t("Entities.Schedule.StopTime")}</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody className="site-exceptions__table-body">
                        {scheduleExceptions?.map((ex: ScheduleException) => {
                            const appliesTo = ex.isFullSiteException
                                ? t("Entities.ScheduleException.AppliesToFullSite")
                                : t("Entities.ScheduleException.AppliesToConfigurationGroupsFormat", {
                                      configurationGroups: ex.configurationGroups
                                          ?.map(
                                              (cg) =>
                                                  cg.name || t("Entities.Default.DefaultDetectionConfiguration", cg),
                                          )
                                          ?.join(", "),
                                  });
                            return (
                                <React.Fragment key={ex.id}>
                                    <TableRow className="site-exceptions__table-row">
                                        <TableCell>
                                            {(() => {
                                                if (!ex.isFullSiteException) {
                                                    return (
                                                        <React.Fragment>
                                                            <FontAwesomeIcon
                                                                className="site-exceptions__status-icon"
                                                                icon={faLowVision}
                                                            />
                                                            {t("Entities.ScheduleException.PartiallyEnabled")}
                                                        </React.Fragment>
                                                    );
                                                }
                                                if (ex.isOnType) {
                                                    return (
                                                        <React.Fragment>
                                                            <FontAwesomeIcon
                                                                className="site-exceptions__status-icon"
                                                                icon={faEye}
                                                            />
                                                            {t("Entities.ScheduleException.Enabled")}
                                                        </React.Fragment>
                                                    );
                                                }
                                                return (
                                                    <React.Fragment>
                                                        <FontAwesomeIcon
                                                            className="site-exceptions__status-icon"
                                                            icon={faEyeSlash}
                                                        />
                                                        {t("Entities.ScheduleException.Disabled")}
                                                    </React.Fragment>
                                                );
                                            })()}
                                        </TableCell>
                                        <TableCell>{formatDateTime(moment(ex.dtStart), site.timeZone)}</TableCell>
                                        <TableCell>{formatDateTime(moment(ex.dtStop), site.timeZone)}</TableCell>
                                    </TableRow>
                                    <TableRow className="site-exceptions__table-row">
                                        <TableCell
                                            colSpan={2}
                                            {...(ex.isFullSiteException ? {} : { title: appliesTo })}
                                        >
                                            {appliesTo}
                                        </TableCell>
                                        {!!props.onRemove && (
                                            <TableCell colSpan={1} align="right">
                                                <Button
                                                    variant="text"
                                                    color="secondary"
                                                    size="small"
                                                    onClick={() => handleRemove(ex)}
                                                    disabled={disableRemoveButton}
                                                    startIcon={
                                                        removeRequestLoading && clicked.includes(ex.id) ? (
                                                            <FontAwesomeIcon icon={faCircleNotch} fixedWidth spin />
                                                        ) : (
                                                            <FontAwesomeIcon icon={faTimes} fixedWidth />
                                                        )
                                                    }
                                                >
                                                    {removeButtonCaption}
                                                </Button>
                                            </TableCell>
                                        )}
                                    </TableRow>
                                </React.Fragment>
                            );
                        })}
                    </TableBody>
                </Table>
            ) : null}
        </>
    );
};
