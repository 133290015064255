import { cloneDeep, forOwn, isEqual } from "lodash";

export class PatchDelta {
    public static difference<T>(original: T, changed: T): T {
        const diff: any = {};

        const changedCopy = cloneDeep(changed);

        forOwn(changedCopy, (value, key) => {
            if (!isEqual((original as any)[key], value)) {
                diff[key] = value;
            }
        });

        return diff as T;
    }
}
