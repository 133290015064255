import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndoAlt as faUndoAltRegular } from "@fortawesome/pro-regular-svg-icons";
import {
    faCheck as faCheckSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { faEye as faEyeLight, faEyeSlash as faEyeSlashLight } from "@fortawesome/pro-light-svg-icons";

import { Profile, SaveDataTransferStatus } from "src/types";
import { isAlphanumerical, isValidAccessCodeLength } from "../ValidationFunctions";

type UserProfileSecurityDialogAccessCodeProps = {
    profile: Profile;
    updateUser: (profile: Profile) => Promise<void>;
    saveProfileStatus: SaveDataTransferStatus;
    onChange: (isDirty: boolean) => void;
    showRevertInformation: boolean;
};

export const UserProfileSecurityDialogAccessCode = (props: UserProfileSecurityDialogAccessCodeProps) => {
    const [originalProfile, setOriginalProfile] = useState<Profile | undefined>(
        props.profile ? { ...props.profile } : undefined,
    );
    const [isDirty, setIsDirty] = useState(false);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const [showAccessCodeValidation, setShowAccessCodeValidation] = useState(false);
    const [showTwoFactorCodeField] = useState(!!props.profile?.twoFactorAuthentication);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [accessCode, setAccessCode] = useState("");
    const [accessCodeLengthValid, setAccessCodeLengthValid] = useState(true);
    const [accessCodeContentValid, setAccessCodeContentValid] = useState(true);
    const [currentPassword, setCurrentPassword] = useState("");
    const [twoFactorAuthCode, setTwoFactorAuthCode] = useState<string>("");

    const { saveProfileStatus, showRevertInformation } = props;

    const { t } = useTranslation();

    const handleIsDirty = () => {
        const result = accessCode !== "";
        setIsDirty(result);
        return result;
    };

    const handleRevertChanges = () => {
        setAccessCode("");
        setShowAccessCodeValidation(false);
    };

    const validateAccessCodeLength = () => {
        const valid = isValidAccessCodeLength(accessCode);
        setAccessCodeLengthValid(valid);
        return valid;
    };

    const validateAccessCodeContent = () => {
        const valid = isAlphanumerical(accessCode);
        setAccessCodeContentValid(valid);
        return valid;
    };

    const validateForm = () => {
        let valid = true;
        if (!validateAccessCodeLength() || !validateAccessCodeContent()) {
            valid = false;
        }

        return valid;
    };

    const handleShowCurrentPassword = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };

    const handleMouseDownPasswordInput = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const saveProfile = () => {
        if (originalProfile !== undefined && validateForm()) {
            const updatedProfile = {
                ...originalProfile,
                accessCode,
                currentPassword,
                twoFactorAuthCode,
            };

            props
                .updateUser(updatedProfile)
                .then(() => {
                    setAccessCode("");
                    setCurrentPassword("");
                    setShowAccessCodeValidation(false);
                    setSaveSuccessful(true);
                })
                .catch(() => {
                    setSaveSuccessful(false);
                });
        }
    };

    useEffect(() => {
        setOriginalProfile(props.profile ? { ...props.profile } : undefined);
    }, [props.profile]);

    useEffect(() => {
        const isDirtyResult = handleIsDirty();
        props.onChange(isDirtyResult);
    }, [originalProfile]);

    useEffect(() => {
        validateAccessCodeLength();
        validateAccessCodeContent();
        if (accessCode) setSaveSuccessful(false);
        const isDirtyResult = handleIsDirty();
        props.onChange(isDirtyResult);
    }, [accessCode]);

    return (
        <>
            <h3 className="title title-h3">{t("Gui.Common.UserProfileSecurityDialog.ChangeAccessCodeTabHeader")}</h3>
            {showRevertInformation ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--multirow notice--warning">
                        <div className="notice__row">
                            <p className="caption caption--alt">
                                {t("Gui.Common.UserProfileDialog.UnsavedChangesSwitchTabsWarning")}
                            </p>
                        </div>
                        <div className="notice__row notice__row--last">
                            <p className="paragraph paragraph--small paragraph--bold paragraph--alt">
                                {t("Gui.Common.UserProfileDialog.UnsavedChangesRevert")}
                                <IconButton
                                    data-testid="access-code-revert-button"
                                    className="notice__icon-button"
                                    size="small"
                                    onClick={handleRevertChanges}
                                >
                                    <FontAwesomeIcon className="icon-button__icon" icon={faUndoAltRegular} />
                                </IconButton>
                            </p>
                        </div>
                    </div>
                </div>
            ) : null}
            {saveProfileStatus.hasError ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--critical">
                        <FontAwesomeIcon className="notice__icon" fixedWidth icon={faExclamationTriangleSolid} />
                        <p className="caption">
                            {saveProfileStatus.errorMessage &&
                            saveProfileStatus.errorMessage.includes("IncorrectCurrentPassword")
                                ? t("Validation.Password.IncorrectCurrentPassword")
                                : t("Error.ErrorSaving")}
                        </p>
                    </div>
                </div>
            ) : null}
            {!saveProfileStatus.hasError && !saveProfileStatus.isLoading && saveSuccessful ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--approve">
                        <FontAwesomeIcon
                            className="notice__icon notice__icon--color-inverted"
                            fixedWidth
                            icon={faCheckSolid}
                        />
                        <p className="caption">
                            {t("Gui.Common.UserProfileSecurityDialog.AccessCodeSavedSuccessfully")}
                        </p>
                    </div>
                </div>
            ) : null}
            <form data-testid="change-access-code-tab" className="form__section form__section--full">
                <div className="form__section form__section--large form__section--column">
                    <FormControl>
                        <label className="caption caption--small-spacing" htmlFor="accessCode">
                            {t("Gui.Common.UserProfileSecurityDialog.AccessCode")}
                        </label>
                        <OutlinedInput
                            inputProps={{ "data-testid": "access-code" }}
                            id="accessCode"
                            required
                            margin="dense"
                            type="text"
                            autoComplete="new-password"
                            value={accessCode}
                            error={!accessCodeLengthValid && showAccessCodeValidation}
                            onBlur={() => {
                                setShowAccessCodeValidation(true);
                            }}
                            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                setAccessCode(e.target.value);
                            }}
                        />
                        {!accessCodeLengthValid && showAccessCodeValidation ? (
                            <FormHelperText error>
                                {t("Validation.UserProfileSecurityDialog.AccessCodeLength")}
                            </FormHelperText>
                        ) : null}
                        {!accessCodeContentValid && showAccessCodeValidation ? (
                            <FormHelperText error>
                                {t("Validation.UserProfileSecurityDialog.AccessCodeContent")}
                            </FormHelperText>
                        ) : null}
                        <FormHelperText className="caption caption--italic">
                            {t("Validation.UserProfileSecurityDialog.AccessCodeExample")}
                        </FormHelperText>
                    </FormControl>
                </div>
                <div className="form__section form__section--alt form__section--full">
                    <div className="form__section form__section--full">
                        <p className="title title--h3">
                            {t("Gui.Common.UserProfileSecurityDialog.ConfirmChangesHeader")}
                        </p>
                        <p className="caption">{t("Gui.Common.UserProfileSecurityDialog.CurrentPasswordNotice")}</p>
                    </div>

                    <div className="form__section form__section--large form__section--column">
                        <FormControl>
                            <label className="caption caption--small-spacing" htmlFor="currentPassword">
                                {t("Gui.Common.UserProfileSecurityDialog.CurrentPassword")}
                            </label>
                            <OutlinedInput
                                inputProps={{ "data-testid": "current-password" }}
                                id="currentPassword"
                                required
                                margin="dense"
                                type={showCurrentPassword ? "text" : "password"}
                                autoComplete="new-password"
                                value={currentPassword}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setCurrentPassword(e.target.value);
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={t(
                                                "Gui.Common.UserProfileSecurityDialog.AriaLabelPasswordVisibility",
                                            )}
                                            onClick={handleShowCurrentPassword}
                                            onMouseDown={handleMouseDownPasswordInput}
                                            edge="end"
                                        >
                                            {showCurrentPassword ? (
                                                <FontAwesomeIcon size="sm" icon={faEyeSlashLight} />
                                            ) : (
                                                <FontAwesomeIcon size="sm" icon={faEyeLight} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    {showTwoFactorCodeField ? (
                        <div className="form__section form__section--large form__section--column">
                            <FormControl>
                                <label className="caption caption--small-spacing" htmlFor="currentPassword">
                                    {t("Gui.Common.UserProfileSecurityDialog.TwoFactorCode")}
                                </label>
                                <OutlinedInput
                                    inputProps={{ "data-testid": "two-factor-code" }}
                                    id="twoFactorCode"
                                    required
                                    margin="dense"
                                    type="text"
                                    autoComplete="off"
                                    value={twoFactorAuthCode}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setTwoFactorAuthCode(e.target.value);
                                    }}
                                />
                            </FormControl>
                        </div>
                    ) : null}
                    <div className="form__section form__section--buttons">
                        <Button
                            disabled={saveProfileStatus.isLoading || !isDirty || !accessCode || !currentPassword}
                            type="button"
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={saveProfile}
                        >
                            {t("Gui.Buttons.SaveAccessCode")}
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};
