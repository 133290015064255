import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import {
    Button,
    Checkbox,
    FormControl,
    FormControlLabel,
    IconButton,
    InputAdornment,
    OutlinedInput,
} from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndoAlt as faUndoAltRegular } from "@fortawesome/pro-regular-svg-icons";
import {
    faCheck as faCheckSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
    faInfoCircle as faInfoCircleSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { faEye as faEyeLight, faEyeSlash as faEyeSlashLight } from "@fortawesome/pro-light-svg-icons";
import { Profile, SaveDataTransferStatus, TwoFactorAuthMode } from "src/types";

type UserProfileSecurityDialogTwoFactorProps = {
    profile: Profile;
    updateUser: (profile: Profile) => Promise<void>;
    saveProfileStatus: SaveDataTransferStatus;
    onChange: (isDirty: boolean) => void;
    showRevertInformation: boolean;
};

export const UserProfileSecurityDialogTwoFactor = (props: UserProfileSecurityDialogTwoFactorProps) => {
    const [originalProfile, setOriginalProfile] = useState<Profile | undefined>(
        props.profile ? { ...props.profile } : undefined,
    );
    const [isDirty, setIsDirty] = useState(false);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const [showEnableTwoFactorHint, setShowEnableTwoFactorHint] = useState(false);
    const [showTwoFactorCodeField, setShowTwoFactorCodeField] = useState(!!props.profile?.twoFactorAuthentication);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [enableTwoFactorAuth, setEnableTwoFactorAuth] = useState(!!props.profile?.twoFactorAuthentication);
    const [currentPassword, setCurrentPassword] = useState("");
    const [twoFactorAuthCode, setTwoFactorAuthCode] = useState<string>("");

    const { saveProfileStatus, showRevertInformation } = props;

    const { t } = useTranslation();

    const handleIsDirty = () => {
        const result = enableTwoFactorAuth !== !!props.profile?.twoFactorAuthentication;
        setIsDirty(result);
        return result;
    };

    const handleRevertChanges = () => {
        setEnableTwoFactorAuth(!!props.profile?.twoFactorAuthentication);
    };

    const handleShowCurrentPassword = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };

    const handleMouseDownPasswordInput = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const saveProfile = () => {
        if (originalProfile !== undefined) {
            const newProfile = {
                ...originalProfile,
                enableTwoFactorAuth,
                currentPassword,
                twoFactorAuthCode,
                twoFactorAuthentication: enableTwoFactorAuth ? TwoFactorAuthMode.GoogleAuthenticator : null,
            };

            props
                .updateUser(newProfile)
                .then(() => {
                    setCurrentPassword("");
                    setShowTwoFactorCodeField(enableTwoFactorAuth);
                    setSaveSuccessful(true);
                })
                .catch(() => {
                    setSaveSuccessful(false);
                });
        }
    };

    useEffect(() => {
        setOriginalProfile(props.profile ? { ...props.profile } : undefined);
    }, [props.profile]);

    useEffect(() => {
        const isDirtyResult = handleIsDirty();
        props.onChange(isDirtyResult);
    }, [originalProfile]);

    useEffect(() => {
        if (enableTwoFactorAuth !== !!props.profile?.twoFactorAuthentication) {
            setSaveSuccessful(false);
        }

        if (!props.profile?.twoFactorAuthentication) {
            if (enableTwoFactorAuth) {
                setShowEnableTwoFactorHint(true);
            } else {
                setShowEnableTwoFactorHint(false);
            }
        } else {
            // never show hint if two-factor already enabled on account
            setShowEnableTwoFactorHint(false);
        }

        const isDirtyResult = handleIsDirty();
        props.onChange(isDirtyResult);
    }, [enableTwoFactorAuth]);

    return (
        <>
            <h3 className="title title-h3">{t("Gui.Common.UserProfileSecurityDialog.ChangeTwoFactorTabHeader")}</h3>
            {showRevertInformation ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--multirow notice--warning">
                        <div className="notice__row">
                            <p className="caption caption--alt">
                                {t("Gui.Common.UserProfileDialog.UnsavedChangesSwitchTabsWarning")}
                            </p>
                        </div>
                        <div className="notice__row notice__row--last">
                            <p className="paragraph paragraph--small paragraph--bold paragraph--alt">
                                {t("Gui.Common.UserProfileDialog.UnsavedChangesRevert")}
                                <IconButton
                                    data-testid="two-factor-revert-button"
                                    className="notice__icon-button"
                                    size="small"
                                    onClick={handleRevertChanges}
                                >
                                    <FontAwesomeIcon className="icon-button__icon" icon={faUndoAltRegular} />
                                </IconButton>
                            </p>
                        </div>
                    </div>
                </div>
            ) : null}
            {saveProfileStatus.hasError ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--critical">
                        <FontAwesomeIcon className="notice__icon" fixedWidth icon={faExclamationTriangleSolid} />
                        <p className="caption">
                            {saveProfileStatus.errorMessage &&
                            saveProfileStatus.errorMessage.includes("IncorrectCurrentPassword")
                                ? t("Validation.Password.IncorrectCurrentPassword")
                                : t("Error.ErrorSaving")}
                        </p>
                    </div>
                </div>
            ) : null}
            {!saveProfileStatus.hasError && !saveProfileStatus.isLoading && saveSuccessful ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--approve">
                        <FontAwesomeIcon
                            className="notice__icon notice__icon--color-inverted"
                            fixedWidth
                            icon={faCheckSolid}
                        />
                        <p className="caption">
                            {t("Gui.Common.UserProfileSecurityDialog.TwoFactorSavedSuccessfully")}
                        </p>
                    </div>
                </div>
            ) : null}
            <form data-testid="change-two-factor-tab" className="form__section form__section--full">
                <div className="form__section form__section--full form__section--column">
                    <FormControl>
                        <FormControlLabel
                            value=""
                            control={
                                <Checkbox
                                    id="two-factor"
                                    checked={enableTwoFactorAuth}
                                    onChange={(e) => {
                                        setEnableTwoFactorAuth(e.target.checked);
                                    }}
                                />
                            }
                            label={t("Gui.Common.UserProfileSecurityDialog.TwoFactorLabel")}
                            labelPlacement="end"
                        />
                        {showEnableTwoFactorHint ? (
                            <div className="notice notice--dark-help notice--arrow-top">
                                <FontAwesomeIcon
                                    className="notice__icon notice__icon--light"
                                    fixedWidth
                                    icon={faInfoCircleSolid}
                                />
                                <div className="caption caption--no-color">
                                    {t("Gui.Common.UserProfileSecurityDialog.EnableTwoFactorHelpText")}
                                </div>
                            </div>
                        ) : null}
                    </FormControl>
                </div>
                <div className="form__section form__section--alt form__section--full">
                    <div className="form__section form__section--full">
                        <p className="title title--h3">
                            {t("Gui.Common.UserProfileSecurityDialog.ConfirmChangesHeader")}
                        </p>
                        <p className="caption">{t("Gui.Common.UserProfileSecurityDialog.CurrentPasswordNotice")}</p>
                    </div>

                    <div className="form__section form__section--large form__section--column">
                        <FormControl>
                            <label className="caption caption--small-spacing" htmlFor="currentPassword">
                                {t("Gui.Common.UserProfileSecurityDialog.CurrentPassword")}
                            </label>
                            <OutlinedInput
                                inputProps={{ "data-testid": "current-password" }}
                                id="currentPassword"
                                required
                                margin="dense"
                                type={showCurrentPassword ? "text" : "password"}
                                autoComplete="new-password"
                                value={currentPassword}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setCurrentPassword(e.target.value);
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={t(
                                                "Gui.Common.UserProfileSecurityDialog.AriaLabelPasswordVisibility",
                                            )}
                                            onClick={handleShowCurrentPassword}
                                            onMouseDown={handleMouseDownPasswordInput}
                                            edge="end"
                                        >
                                            {showCurrentPassword ? (
                                                <FontAwesomeIcon size="sm" icon={faEyeSlashLight} />
                                            ) : (
                                                <FontAwesomeIcon size="sm" icon={faEyeLight} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    {showTwoFactorCodeField ? (
                        <div className="form__section form__section--large form__section--column">
                            <FormControl>
                                <label className="caption caption--small-spacing" htmlFor="currentPassword">
                                    {t("Gui.Common.UserProfileSecurityDialog.TwoFactorCode")}
                                </label>
                                <OutlinedInput
                                    inputProps={{ "data-testid": "two-factor-code" }}
                                    id="twoFactorCode"
                                    required
                                    margin="dense"
                                    type="text"
                                    autoComplete="off"
                                    value={twoFactorAuthCode}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setTwoFactorAuthCode(e.target.value);
                                    }}
                                />
                            </FormControl>
                        </div>
                    ) : null}
                    <div className="form__section form__section--buttons">
                        <Button
                            disabled={saveProfileStatus.isLoading || !isDirty || !currentPassword}
                            type="button"
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={saveProfile}
                        >
                            {t("Gui.Buttons.SaveTwoFactor")}
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};
