import React from "react";

import { ListItem } from "@material-ui/core";
import { Link as RouterLink, LinkProps as RouterLinkProps } from "react-router-dom";

type ListItemLinkProps = {
    to: string;
    children: any;
};

export const ListItemLink: React.FC<ListItemLinkProps> = (props: ListItemLinkProps) => {
    const { children, to } = props;

    const renderLink = React.useMemo(
        () =>
            // eslint-disable-next-line react/display-name
            React.forwardRef<HTMLAnchorElement, Omit<RouterLinkProps, "innerRef" | "to">>((itemProps, ref) => (
                // With react-router-dom@^6.0.0 use `ref` instead of `innerRef`
                // See https://github.com/ReactTraining/react-router/issues/6056
                <RouterLink to={to} {...itemProps} innerRef={ref} />
            )),
        [to],
    );
    return (
        <ListItem button={true} component={renderLink}>
            {children}
        </ListItem>
    );
};
