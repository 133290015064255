import React, { Component } from "react";
import { createStyles, Theme, withStyles, WithStyles, CircularProgress } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";
import { interval } from "rxjs";

import { fetchImageWithAccessToken } from "src/utils";
import { Alarm } from "../../../entities";
import { apiUrl } from "../../../settings";

import "./_alarm-thumbnail.scss";

const styles = () => createStyles({});

type OwnProps = {
    theme: Theme;
    alarm: Alarm;
};

type OwnState = {
    offset: number;
    frame: number;
    videoLoadingError: boolean;
    subscription: any;
    imageUrl: string;
};

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class AlarmThumbnailInternal extends Component<Props, any> {
    constructor(props: Props) {
        super(props);
        const state = {
            offset: 0,
            frame: 0,
            videoLoadingError: false,
            subscription: interval(400).subscribe(() => this.animate()),
            imageUrl: "",
            loading: false,
        };

        this.state = state;
    }

    componentDidMount() {
        this.fetchImage();
    }

    componentDidUpdate(prevProps: OwnProps) {
        if (prevProps.alarm.id !== this.props.alarm.id) {
            this.setState(
                (state) => {
                    return {
                        ...state,
                        offset: 0,
                        frame: 0,
                        videoLoadingError: false,
                    };
                },
                () => {
                    this.fetchImage();
                },
            );
        }
    }

    componentWillUnmount() {
        this.state.subscription.unsubscribe();
    }

    fetchImage() {
        const url = `${apiUrl}/events/${this.props.alarm.friendlyId}/video/sprite`;
        this.setState((state) => ({
            ...state,
            loading: true,
        }));
        const sub = fetchImageWithAccessToken(url).subscribe((response) => {
            sub.unsubscribe();
            if (!response.error) {
                this.setState((state) => ({
                    ...state,
                    loading: false,
                    imageUrl: response.url,
                }));
            } else {
                this.handleVideoLoadingError();
            }
        });
    }

    animate = () => {
        this.setState(
            (state: OwnState) => {
                state.frame = (state.frame + 1) % 4;
                state.offset = (state.frame * 100) / 3;

                return state;
            },
            () => {
                this.forceUpdate();
            },
        );
    };

    handleVideoLoadingError = () => {
        this.setState((state) => {
            state.videoLoadingError = true;
            state.loading = false;
            return state;
        });
    };

    render() {
        const { t } = this.props;
        return (
            <div className="alarm-thumbnail">
                {this.state.loading || this.state.videoLoadingError ? (
                    <React.Fragment>
                        {this.state.loading ? (
                            <div
                                className="alarm-thumbnail__loader"
                                style={{
                                    paddingBottom: `${
                                        (this.props.alarm.image.image.height / this.props.alarm.image.image.width) * 100
                                    }%`,
                                }}
                            >
                                <CircularProgress size={25} />
                            </div>
                        ) : null}
                        {this.state.videoLoadingError ? (
                            <span className="emphasis">{t("Entities.VideoNotFound")}</span>
                        ) : null}
                    </React.Fragment>
                ) : null}
                {!this.state.loading && this.state.imageUrl ? (
                    <React.Fragment>
                        <img
                            id="errorChecker"
                            src={this.state.imageUrl}
                            onError={this.handleVideoLoadingError}
                            hidden
                        />
                        {!this.state.videoLoadingError ? (
                            <div
                                className="alarm-thumbnail__image-animator"
                                style={{
                                    backgroundImage: `url('${this.state.imageUrl}')`,
                                    backgroundPosition: `0px ${this.state.offset}%`,
                                    paddingBottom: `${
                                        (this.props.alarm.image.image.height / this.props.alarm.image.image.width) * 100
                                    }%`,
                                }}
                            />
                        ) : null}
                    </React.Fragment>
                ) : null}
            </div>
        );
    }
}

export const AlarmThumbnail = withTranslation()(withStyles(styles, { withTheme: true })(AlarmThumbnailInternal));
