import React from "react";
import { Redirect, Route } from "react-router-dom";
import { getToken } from "../../utils";

export const RedirectRoute: React.FC<any> = (props: { redirectIfLoggedIn: string; path: string; component: any }) => {
    const token = getToken();

    if (token) {
        return <Redirect to={props.redirectIfLoggedIn} />;
    }

    return <Route path={props.path} component={props.component} />;
};
