import React, { ReactNode } from "react";
import { Button, Dialog, DialogActions, DialogContent, DialogTitle, Typography } from "@material-ui/core";

import { useTranslation } from "react-i18next";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/pro-light-svg-icons";

type OkCancelDialogProps = {
    okButtonText: string;
    onClose: (ok: boolean) => void;
    children?: ReactNode;
    title: string;
    open: boolean;
};

export const OkCancelDialog = (props: OkCancelDialogProps) => {
    const { t } = useTranslation();
    const { okButtonText, onClose, open, title, children } = props;

    const handleOk = () => {
        onClose(true);
    };

    const handleCancel = () => {
        onClose(false);
    };

    return (
        <Dialog open={open} maxWidth="xs" disableBackdropClick disablePortal onClose={() => handleCancel()}>
            <DialogTitle>
                <Typography variant="h6">{title}</Typography>
            </DialogTitle>
            <DialogContent dividers>{children}</DialogContent>
            <DialogActions>
                <Button autoFocus variant="contained" color="default" onClick={() => handleCancel()}>
                    {t("Gui.Buttons.Cancel")}
                </Button>
                <Button
                    variant="contained"
                    color="primary"
                    size="large"
                    startIcon={<FontAwesomeIcon fixedWidth icon={faCheck} />}
                    onClick={() => handleOk()}
                >
                    {t(okButtonText ?? "Gui.Buttons.Ok")}
                </Button>
            </DialogActions>
        </Dialog>
    );
};
