import React, { useEffect, useState } from "react";
import {
    Box,
    FormControl,
    InputLabel,
    Card,
    CardContent,
    Typography,
    Select,
    MenuItem,
    LinearProgress,
    Fade,
} from "@material-ui/core/";
import { Skeleton } from "@material-ui/lab";
import moment, { Moment } from "moment-timezone";
import { RouteComponentProps, Switch, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useAlarms } from "src/hooks/useAlarms";
import { makeApiUrl } from "src/utils";
import { fetchWithIntercept } from "src/services/fetchService";
import { useAuth } from "src/contexts/AuthStateContext";
import { Alarm, AlarmOverlay, AlarmsPeriod, Site } from "../../../entities";
import { PropsRoute } from "../../Core/PropsRoute";

import { AlarmDetails } from "./AlarmDetails";
import { AlarmsGridList } from "./AlarmsGridList";

import "./SiteAlarms.scss";

type Props = {
    site: Site;
} & RouteComponentProps;

export const SiteAlarms = (props: Props) => {
    const { location, match, site } = props;

    const { logout } = useAuth();

    const initialPeriod: AlarmsPeriod = "Today";
    const initialTo = moment.utc().tz(site.timeZone).endOf("day");
    const initialFrom = moment(initialTo).startOf("day");

    const [siteAlarmsUrl] = useState(match.url);
    const [alarmsPeriod, setAlarmsPeriod] = useState<AlarmsPeriod>(initialPeriod);
    const [from, setFrom] = useState(initialFrom);
    const [to, setTo] = useState(initialTo);
    const [selectedAlarmId, setSelectedAlarmId] = useState<string | undefined>(undefined);
    const [selectedAlarm, setSelectedAlarm] = useState<Alarm | undefined>(undefined);

    const { t } = useTranslation();
    const history = useHistory();
    const { alarms, alarmsIsLoading, alarmsHasError, changeDates } = useAlarms(site.id, from, to);

    const resetComponent = () => {
        history.push(siteAlarmsUrl);
        setSelectedAlarmId(undefined);
        setSelectedAlarm(undefined);
    };

    const updateSelectedAlarm = (alarm: Alarm) => {
        const fetchOverlay = async () => {
            const overlayResponse = await fetchWithIntercept(alarm.overlayUrl, "GET", logout, null, undefined, true);
            const overlayJson = (await overlayResponse.json()) as AlarmOverlay;
            return overlayJson;
        };

        if (alarm && !alarm.overlay && alarm.overlayUrl) {
            fetchOverlay()
                .then((alarmOverlay) => {
                    alarm.overlay = alarmOverlay;
                    setSelectedAlarm(alarm);
                })
                .catch((overlayError) => console.log(overlayError));
        } else {
            setSelectedAlarm(alarm);
        }
    };

    useEffect(() => {
        const alarmId = location.pathname.substring(match.url.length + 1);

        if (alarmId && alarmId.length) {
            setSelectedAlarmId(alarmId);
            const alarm = alarms?.find((_alarm) => _alarm.friendlyId === alarmId);

            if (alarm) {
                updateSelectedAlarm(alarm);
            } else {
                const loadSingleAlarm = async (_alarmId: string) => {
                    const url = makeApiUrl(
                        `/sites/${site.id}/events/${_alarmId}?generateVideoUrl=true&generateOverlayUrl=true`,
                    );

                    const alarmResponse = await fetchWithIntercept(url, "GET", logout);
                    const alarmJson = await alarmResponse.json();
                    updateSelectedAlarm(alarmJson);
                };

                loadSingleAlarm(alarmId).catch((alarmError) => console.error(alarmError));
            }
        }
    }, [location]);

    const onDateChanged = (e: React.ChangeEvent<{ value: unknown }>) => {
        resetComponent();

        const newPeriod = e.target.value as AlarmsPeriod;
        let newFrom: Moment | undefined;
        let newTo: Moment | undefined;

        switch (newPeriod) {
            case "Today":
                newTo = moment.utc().tz(site.timeZone).endOf("day");
                newFrom = moment(newTo).startOf("day");
                break;
            case "Yesterday":
                newTo = moment.utc().tz(site.timeZone).subtract(1, "days").endOf("day");
                newFrom = moment(newTo).startOf("day");
                break;
            case "ThisWeek":
                newTo = moment.utc().tz(site.timeZone).endOf("week");
                newFrom = moment(newTo).startOf("week");
                break;
            case "LastWeek":
                newTo = moment.utc().tz(site.timeZone).subtract(1, "week").endOf("week");
                newFrom = moment(newTo).startOf("week");
                break;
            case "ThisMonth":
                newTo = moment.utc().tz(site.timeZone).endOf("month");
                newFrom = moment(newTo).startOf("month");
                break;
            case "LastMonth":
                newTo = moment.utc().tz(site.timeZone).subtract(1, "month").endOf("month");
                newFrom = moment(newTo).startOf("month");
                break;
            case "TwoMonthsAgo":
                newTo = moment.utc().tz(site.timeZone).subtract(2, "month").endOf("month");
                newFrom = moment(newTo).startOf("month");
                break;
            default:
                newTo = undefined;
                newFrom = undefined;
                break;
        }

        if (newFrom && newTo) {
            setFrom(newFrom);
            setTo(newTo);
            setAlarmsPeriod(newPeriod);
            changeDates(newFrom, newTo);
        }
    };

    return (
        <div className="site-alarms">
            <Fade in={alarmsIsLoading} timeout={{ enter: 700, exit: 400 }}>
                <LinearProgress />
            </Fade>
            <Card className="site-alarms__card">
                <Fade in={true} timeout={{ enter: 150 }}>
                    <CardContent className="site-alarms__tab">
                        <Box>
                            <Box mb={3} width={{ md: 300 }}>
                                <FormControl variant="filled" fullWidth={true} margin={"dense"}>
                                    <InputLabel id="date-select-week-label">
                                        {t("Entities.AlarmEvents.FilterPeriod")}
                                    </InputLabel>
                                    <Select id="date-select-week" onChange={onDateChanged} value={alarmsPeriod}>
                                        <MenuItem value={"Today"}>{t("Gui.Common.Timespan.Today")}</MenuItem>
                                        <MenuItem value={"Yesterday"}>{t("Gui.Common.Timespan.Yesterday")}</MenuItem>
                                        <MenuItem value={"ThisWeek"}>{t("Gui.Common.Timespan.ThisWeek")}</MenuItem>
                                        <MenuItem value={"LastWeek"}>{t("Gui.Common.Timespan.LastWeek")}</MenuItem>
                                        <MenuItem value={"ThisMonth"}>{t("Gui.Common.Timespan.ThisMonth")}</MenuItem>
                                        <MenuItem value={"LastMonth"}>{t("Gui.Common.Timespan.LastMonth")}</MenuItem>
                                        <MenuItem value={"TwoMonthsAgo"}>
                                            {t("Gui.Common.Timespan.TwoMonthsAgo")}
                                        </MenuItem>
                                    </Select>
                                </FormControl>
                            </Box>
                            {!alarmsIsLoading && site.timeZone !== "" ? (
                                <React.Fragment>
                                    {alarms && alarms?.length > 0 ? (
                                        <AlarmsGridList
                                            alarms={alarms}
                                            timeZone={site.timeZone}
                                            selectedAlarmId={selectedAlarmId}
                                        />
                                    ) : null}
                                    {alarmsHasError ? (
                                        <Typography variant="h6" align="center" gutterBottom={true}>
                                            {t("Entities.AlarmEvent.NoAlarmDataAvailable")}
                                        </Typography>
                                    ) : null}
                                </React.Fragment>
                            ) : (
                                <Fade in={alarmsIsLoading} timeout={{ enter: 700, exit: 400 }}>
                                    <Box mx={1} mb={1}>
                                        <Skeleton variant="rect" height={280} width="100%" />
                                    </Box>
                                </Fade>
                            )}
                            <Switch>
                                <PropsRoute
                                    path={`${match.url}/:alarmId`}
                                    component={AlarmDetails}
                                    withProps={{
                                        site,
                                        alarm: selectedAlarm,
                                    }}
                                />
                            </Switch>
                        </Box>
                    </CardContent>
                </Fade>
            </Card>
        </div>
    );
};
