import moment from "moment-timezone";
import { useEffect, useState } from "react";
import { ScheduleException, ScheduleExceptionDelete, ScheduleExceptionPost } from "src/entities";
import { updateScheduleException, addScheduleException } from "src/services/scheduleExceptionService";
import { apiUrl } from "src/settings";
import { useData } from "./useData";

const useScheduleExceptionsInternal = (siteId: string) => {
    const url = `${apiUrl}/sites/${siteId}/scheduleExceptions`;
    const {
        data: scheduleExceptionsList,
        isLoading: scheduleExceptionsIsLoading,
        hasError: scheduleExceptionsHasError,
        refreshData: refreshScheduleExceptions,
    } = useData<ScheduleException[]>(url);

    if (scheduleExceptionsList) {
        Object.values(scheduleExceptionsList).forEach((e) => {
            e.dtStart = moment.utc(e.dtStart);
            e.dtStop = moment.utc(e.dtStop);
        });
    }

    return {
        scheduleExceptionsList,
        scheduleExceptionsIsLoading,
        scheduleExceptionsHasError,
        refreshScheduleExceptions,
    };
};

export const useScheduleExceptions = (props: { siteId: string; logout: () => void }) => {
    const { siteId, logout } = props;
    const [scheduleExceptionsPostIsLoading, setScheduleExceptionsPostIsLoading] = useState(false);
    const [scheduleExceptionsPostHasError, setScheduleExceptionsPostHasError] = useState(false);
    const [scheduleExceptionsDeleteIsLoading, setScheduleExceptionsDeleteIsLoading] = useState(false);
    const [scheduleExceptionsDeleteHasError, setScheduleExceptionsDeleteHasError] = useState(false);
    const [scheduleExceptions, setScheduleExceptions] = useState<ScheduleException[]>([]);

    const {
        scheduleExceptionsList,
        scheduleExceptionsIsLoading,
        scheduleExceptionsHasError,
        refreshScheduleExceptions,
    } = useScheduleExceptionsInternal(siteId);

    useEffect(() => {
        if (scheduleExceptionsList) {
            setScheduleExceptions(scheduleExceptionsList);
        }
    }, [scheduleExceptionsList]);

    const postException = async (scheduleException: ScheduleExceptionPost) => {
        setScheduleExceptionsPostIsLoading(true);
        setScheduleExceptionsPostHasError(false);

        try {
            await addScheduleException(siteId, scheduleException, logout);
        } catch (err) {
            setScheduleExceptionsPostHasError(true);
        }

        refreshScheduleExceptions();

        setScheduleExceptionsPostIsLoading(false);
    };

    const deleteException = async (scheduleException: ScheduleExceptionDelete) => {
        setScheduleExceptionsDeleteIsLoading(true);
        setScheduleExceptionsDeleteHasError(false);

        try {
            await updateScheduleException(siteId, scheduleException, logout);
        } catch (error) {
            setScheduleExceptionsDeleteHasError(true);
        }

        refreshScheduleExceptions();

        setScheduleExceptionsDeleteIsLoading(false);
    };

    return {
        scheduleExceptions,
        scheduleExceptionsIsLoading,
        scheduleExceptionsHasError,
        scheduleExceptionsPostIsLoading,
        scheduleExceptionsPostHasError,
        scheduleExceptionsDeleteIsLoading,
        scheduleExceptionsDeleteHasError,
        postException,
        deleteException,
    };
};
