import { useEffect, useMemo, useState } from "react";
import { Site, SiteStatistics } from "src/entities";
import { apiUrl } from "src/settings";
import _orderBy from "lodash/orderBy";
import { useData } from "./useData";

type SortDirection = "asc" | "desc";
type SortProperty = "name" | "eventsLastWeek" | "eventsLastDay";

const useSites = () => {
    const url = `${apiUrl}/sites`;
    const { data: sitesList, isLoading, hasError } = useData<Site[]>(url);
    return { sitesList, sitesLoading: isLoading, sitesHasError: hasError };
};

const useSiteStatistics = () => {
    const url = `${apiUrl}/sites/siteStatistics`;
    const { data: siteStatistics, isLoading, hasError } = useData<SiteStatistics[]>(url);
    return { siteStatistics, siteStatisticsLoading: isLoading, siteStatisticsHasError: hasError };
};

function getSorterForProperty(property: string) {
    switch (property) {
        case "name":
            return (site: Site) => site[property].toLowerCase();
        case "eventsLastDay":
        case "eventsLastWeek":
            return (site: Site) => site[property] || (site[property] === 0 ? 0 : -1);
        default:
            return (site: Site) => site[property];
    }
}

export const useSiteList = () => {
    const [sortProperty, setSortProperty] = useState<SortProperty>("eventsLastWeek");
    const [sortDirection, setSortDirection] = useState<SortDirection>("desc");
    const [sites, setSites] = useState<Site[]>([]);
    const [siteListIsLoading, setSiteListIsLoading] = useState(true);

    const { sitesList, sitesLoading, sitesHasError } = useSites();
    const { siteStatistics, siteStatisticsLoading, siteStatisticsHasError } = useSiteStatistics();

    const sortSites = (property: SortProperty) => {
        if (sortProperty === property) {
            setSortDirection(sortDirection === "asc" ? "desc" : "asc");
        } else {
            setSortProperty(property);

            if (property === "name") {
                setSortDirection("asc");
            } else {
                setSortDirection("desc");
            }
        }
    };

    useMemo(() => {
        setSiteListIsLoading(true);

        if (sitesList && siteStatistics) {
            const mappedSites = sitesList
                ?.filter((site) => site.siteState !== "Ended" && site.siteState !== "NotStarted")
                .map((site) => {
                    const statistics = siteStatistics?.find((stat) => stat.id === site.id);
                    const mappedSite = {
                        ...site,
                        ...statistics,
                    } as Site;

                    return mappedSite;
                });

            setSites(_orderBy(mappedSites, [getSorterForProperty(sortProperty)], [sortDirection]) || []);
            setSiteListIsLoading(false);
        }
    }, [sitesList, siteStatistics]);

    useEffect(() => {
        const orderedSites =
            sites && sites.length > 0 ? _orderBy(sites, [getSorterForProperty(sortProperty)], [sortDirection]) : [];
        setSites(orderedSites);
    }, [sortProperty, sortDirection]);

    return {
        sites,
        sitesLoading,
        sitesHasError,
        siteStatistics,
        siteStatisticsLoading,
        siteStatisticsHasError,
        sortSites,
        sortProperty,
        sortDirection,
        siteListIsLoading,
    };
};
