import store, { StoreAPI } from "store2";
import moment from "moment-timezone";
import { LoginDetails } from "src/types";
import { UserProfile } from "../entities";
// import { LoginDetails } from "../actions";
import { changeLanguage } from "./i18n";

let irStore: StoreAPI | undefined;
export const getIrStore = (): StoreAPI => {
    if (!irStore) {
        irStore = store.namespace("ir");
    }
    return irStore;
};

export const getUserLanguage = () => {
    let language: string = getIrStore().get("language") || getBrowserLanguage();
    if (language && language.length > 2) {
        language = language.substr(0, 2);
    }
    return language;
};
export const getUserLocale = () => getIrStore().get("locale") || getBrowserLanguage();
export const getUserProfile = () => getIrStore().get("profile");
const storeUserLanguage = (language: string) => {
    getIrStore().set("language", language);
};
const storeUserLocale = (locale: string) => {
    getIrStore().set("locale", locale);
};
export const removeToken = () => getIrStore().remove("token");
export const removeUserProfile = () => getIrStore().remove("profile");

export const clearStorage = () => {
    const language = getUserLanguage();
    const locale = getUserLocale();
    getIrStore().clear();
    if (language) {
        storeUserLanguage(language);
    }
    if (locale) {
        storeUserLocale(locale);
    }
};

export const getToken: () => LoginDetails | undefined = () => {
    let token: LoginDetails | undefined = getIrStore().get("token") as LoginDetails;
    if (token) {
        if (moment().isAfter(token.accessTokenExpiresAt)) {
            removeToken();
            token = undefined;
        }
    }
    return token;
};

export const getUserLastKnownRoute = () => {
    return getIrStore().get("lastKnownRoute");
};

export const setUserLastKnownRoute = (route: string) => {
    getIrStore().set("lastKnownRoute", route);
};

export const clearUserLastKnownRoute = () => {
    getIrStore().remove("lastKnownRoute");
};

export const storeUserProfile = (profile: UserProfile) => {
    getIrStore().set("profile", profile);
    if (profile.locale && profile.language) {
        moment.locale(profile.locale);
        // eslint-disable-next-line @typescript-eslint/no-floating-promises
        changeLanguage(profile.language.key);
        storeUserLanguage(profile.language.key);
        storeUserLocale(profile.locale);
    }
};

export const storeToken = (token: LoginDetails) => {
    getIrStore().set("token", token);
};

function getBrowserLanguage(): string {
    const nav = window.navigator as any;
    const browserLanguagePropertyKeys = ["language", "browserLanguage", "systemLanguage", "userLanguage"];
    let i: number;
    let language: string;

    // support for HTML 5.1 "navigator.languages"
    if (Array.isArray(nav.languages)) {
        for (i = 0; i < nav.languages.length; i++) {
            language = nav.languages[i];
            if (language && language.length) {
                return language;
            }
        }
    }

    // support for other well known properties in browsers
    for (i = 0; i < browserLanguagePropertyKeys.length; i++) {
        language = nav[browserLanguagePropertyKeys[i]];
        if (language && language.length) {
            return language;
        }
    }
    return "en";
}
