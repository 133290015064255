import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, IconButton, MenuItem, TextField } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndoAlt as faUndoAltRegular } from "@fortawesome/pro-regular-svg-icons";
import {
    faCheck as faCheckSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
} from "@fortawesome/pro-solid-svg-icons";

import { GetDataTransferStatus, IrMap, Language, Profile, SaveDataTransferStatus } from "src/types";
import { jsonEqual } from "src/utils";

type UserProfileIntroDialogLocaleProps = {
    profile: Profile;
    languages: Language[];
    locales: string[];
    timeZones: IrMap<string, string>;
    updateUser: (profile: Profile) => Promise<void>;
    saveProfileStatus: SaveDataTransferStatus;
    getLocalizationStatus: GetDataTransferStatus;
    onChange: (isDirty: boolean) => void;
    showRevertInformation: boolean;
};

export const UserProfileIntroDialogLocale = (props: UserProfileIntroDialogLocaleProps) => {
    const [originalProfile, setOriginalProfile] = useState<Profile>(props.profile);
    const [isDirty, setIsDirty] = useState(false);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const [timeZoneName, setTimeZoneName] = useState<string>(props.profile.timeZone.name);
    const [timeZoneNameValid, setTimeZoneNameValid] = useState(true);
    const [languageKey, setLanguageKey] = useState<string>(props.profile.language.key);
    const [languageKeyValid, setLanguageKeyValid] = useState(true);
    const [locale, setLocale] = useState<string>(props.profile.locale);
    const [localeValid, setLocaleValid] = useState(true);

    const { getLocalizationStatus, saveProfileStatus, showRevertInformation } = props;

    const { t } = useTranslation();

    const handleIsDirty = () => {
        let result = false;

        if (
            originalProfile.timeZone.name !== timeZoneName ||
            originalProfile.language.key !== languageKey ||
            originalProfile.locale !== locale
        ) {
            result = true;
        }

        setIsDirty(result);
        return result;
    };

    const handleRevertChanges = () => {
        setTimeZoneName(originalProfile.timeZone.name);
        setLanguageKey(originalProfile.language.key);
        setLocale(originalProfile.locale);
    };

    const validateTimeZone = (value: string | undefined) => {
        let valid = true;
        if (!value) {
            valid = false;
        }
        setTimeZoneNameValid(valid);
        return valid;
    };

    const handleChangeTimeZone = (e: React.ChangeEvent<HTMLInputElement>) => {
        const selectedTimeZone = props.timeZones.find((tz) => jsonEqual(tz.key, e.target.value));
        if (selectedTimeZone) {
            setTimeZoneName(selectedTimeZone.key);
        }
    };

    const validateLanguage = (value: string | undefined) => {
        let valid = true;
        if (!value) {
            valid = false;
        }
        setLanguageKeyValid(valid);
        return valid;
    };

    const handleChangeLanguage = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLanguageKey(e.target.value);
    };

    const validateLocale = (value: string | undefined) => {
        let valid = true;
        if (!value) {
            valid = false;
        }
        setLocaleValid(valid);
        return valid;
    };

    const handleChangeLocale = (e: React.ChangeEvent<HTMLInputElement>) => {
        setLocale(e.target.value);
    };

    const validateLocaleForm = () => {
        let valid = true;
        if (!validateTimeZone(timeZoneName) || !validateLanguage(languageKey) || !validateLocale(locale)) {
            valid = false;
        }

        return valid;
    };

    const saveLocaleTab = () => {
        if (originalProfile !== undefined && validateLocaleForm()) {
            const language = props.languages?.find((lang) => lang.key === languageKey) as Language;

            const updatedProfile = {
                ...originalProfile,
                timeZone: { id: originalProfile.timeZone.id, name: timeZoneName },
                language,
                locale,
            };

            props
                .updateUser(updatedProfile)
                .then(() => {
                    // setOriginalProfile(props.profile);
                    setSaveSuccessful(true);
                })
                .catch(() => {
                    setSaveSuccessful(false);
                });
        }
    };

    useEffect(() => {
        setOriginalProfile(props.profile);
    }, [props.profile]);

    useEffect(() => {
        const isDirtyResult = handleIsDirty();
        props.onChange(isDirtyResult);
    }, [originalProfile]);

    useEffect(() => {
        const isDirtyResult = handleIsDirty();
        if (isDirtyResult) setSaveSuccessful(false);
        props.onChange(isDirtyResult);
    }, [timeZoneName, languageKey, locale]);

    return (
        <>
            <div className="user-profile-details__header-with-loader">
                <h3 className="title title-h3">{t("Gui.Common.UserProfileIntroDialog.LocaleTabHeader")}</h3>
                {getLocalizationStatus.isLoading ? (
                    <CircularProgress data-testid="locale-form-loading-data" className="spinner" />
                ) : null}
            </div>
            {showRevertInformation ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--multirow notice--warning">
                        <div className="notice__row">
                            <p className="caption caption--alt">
                                {t("Gui.Common.UserProfileDialog.UnsavedChangesSwitchTabsWarning")}
                            </p>
                        </div>
                        <div className="notice__row notice__row--last">
                            <p className="paragraph paragraph--small paragraph--bold paragraph--alt">
                                {t("Gui.Common.UserProfileDialog.UnsavedChangesRevert")}
                                <IconButton
                                    color="inherit"
                                    data-testid="locale-revert-button"
                                    className="notice__icon-button"
                                    size="small"
                                    onClick={handleRevertChanges}
                                >
                                    <FontAwesomeIcon className="icon-button__icon" icon={faUndoAltRegular} />
                                </IconButton>
                            </p>
                        </div>
                    </div>
                </div>
            ) : null}
            {saveProfileStatus.hasError ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--critical">
                        <FontAwesomeIcon className="notice__icon" fixedWidth icon={faExclamationTriangleSolid} />
                        <p className="caption">{t("Error.ErrorSaving")}</p>
                    </div>
                </div>
            ) : null}
            {!saveProfileStatus.hasError && !saveProfileStatus.isLoading && saveSuccessful ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--approve">
                        <FontAwesomeIcon className="notice__icon" fixedWidth icon={faCheckSolid} />
                        <p className="caption">
                            {t("Gui.Common.UserProfileIntroDialog.LocalizationSavedSuccessfully")}
                        </p>
                    </div>
                </div>
            ) : null}
            <form
                data-testid="dialog-locale-tab"
                className="form__section form__section--full form__section--alt form__section--column"
            >
                <div className="form__section">
                    <label className="caption caption--small-spacing" htmlFor="timeZone">
                        {t("Gui.Common.UserProfileIntroDialog.Timezone")}
                    </label>
                    <TextField
                        inputProps={{ "data-testid": "timeZone" }}
                        id="timeZone"
                        required
                        fullWidth
                        variant="outlined"
                        size="small"
                        select
                        autoComplete="off"
                        disabled={getLocalizationStatus.isLoading}
                        value={timeZoneName}
                        helperText={!timeZoneNameValid ? t("Validation.Mixed.Required") : null}
                        error={!timeZoneNameValid}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleChangeTimeZone(e);
                            validateTimeZone(e.target.value);
                        }}
                    >
                        {!props.timeZones ? (
                            <MenuItem value={timeZoneName} />
                        ) : (
                            props.timeZones
                                .sort((a, b) => {
                                    if (a.value < b.value) {
                                        return -1;
                                    }
                                    if (a.value > b.value) {
                                        return 1;
                                    }
                                    return 0;
                                })
                                .map((tz) => (
                                    <MenuItem key={tz.key} value={tz.key}>
                                        {t(`Entities.TimeZone.${tz.key}`)}
                                    </MenuItem>
                                ))
                        )}
                    </TextField>
                </div>
                <div className="form__section">
                    <label className="caption caption--small-spacing" htmlFor="language">
                        {t("Gui.Common.UserProfileIntroDialog.Language")}
                    </label>
                    <TextField
                        inputProps={{ "data-testid": "language" }}
                        id="language"
                        required
                        fullWidth
                        variant="outlined"
                        size="small"
                        select
                        autoComplete="off"
                        disabled={getLocalizationStatus.isLoading}
                        value={languageKey}
                        helperText={!languageKeyValid ? t("Validation.Mixed.Required") : null}
                        error={!languageKeyValid}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleChangeLanguage(e);
                            validateLanguage(e.target.value);
                        }}
                    >
                        {!props.languages ? (
                            <MenuItem value={languageKey} />
                        ) : (
                            props.languages
                                .sort((a, b) => {
                                    if (a.key < b.key) {
                                        return -1;
                                    }
                                    if (a.key > b.key) {
                                        return 1;
                                    }
                                    return 0;
                                })
                                .map((lang) => (
                                    <MenuItem key={lang.key} value={lang.key}>
                                        {t(`Entities.Language.${lang.key}`)}
                                    </MenuItem>
                                ))
                        )}
                    </TextField>
                </div>
                <div className="form__section">
                    <label className="caption caption--small-spacing" htmlFor="locale">
                        {t("Gui.Common.UserProfileIntroDialog.Locale")}
                    </label>
                    <TextField
                        inputProps={{ "data-testid": "locale" }}
                        id="locale"
                        required
                        fullWidth
                        variant="outlined"
                        size="small"
                        select
                        autoComplete="off"
                        disabled={getLocalizationStatus.isLoading}
                        value={locale}
                        helperText={!localeValid ? t("Validation.Mixed.Required") : null}
                        error={!localeValid}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            handleChangeLocale(e);
                            validateLocale(e.target.value);
                        }}
                    >
                        {!props.locales ? (
                            <MenuItem value={locale} />
                        ) : (
                            props.locales
                                .sort((a, b) => {
                                    if (a < b) {
                                        return -1;
                                    }
                                    if (a > b) {
                                        return 1;
                                    }
                                    return 0;
                                })
                                .map((loc) => (
                                    <MenuItem key={loc} value={loc}>
                                        {t(`Entities.Locale.${loc}`)}
                                    </MenuItem>
                                ))
                        )}
                    </TextField>
                </div>
                <div className="dialog__section dialog__section--buttons">
                    <Button
                        data-testid="save-locale-button"
                        disabled={saveProfileStatus.isLoading || getLocalizationStatus.isLoading || !isDirty}
                        type="button"
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={saveLocaleTab}
                    >
                        {t("Gui.Buttons.SaveChanges")}
                    </Button>
                </div>
            </form>
        </>
    );
};
