import React from "react";
import { ConfigurationGroupSchedule } from "src/entities/ConfigurationGroup";

type Props = {
    schedule: ConfigurationGroupSchedule | null;
};

export const Schedule = (props: Props) =>
    props.schedule ? (
        <div className="data-list">
            {props.schedule &&
                props.schedule.weeklySchedule.daySchedules.map((a, index) => (
                    <div className="data-list__row data-list__row--extra-spacing" key={`day-${index}`}>
                        <p className="paragraph paragraph--no-spacing paragraph--bold">{a.dayType}</p>
                        <div className="data-list__line" />
                        <div>
                            {a.scheduleTimePeriods.map((period, timePeriodIndex) => (
                                <p
                                    key={timePeriodIndex}
                                    className="caption caption--tiny-spacing"
                                >{`${period.startTime.substring(0, 5)} – ${period.stopTime.substring(0, 5)}`}</p>
                            ))}
                        </div>
                    </div>
                ))}
        </div>
    ) : null;
