import moment, { Moment } from "moment-timezone";
import { useCallback, useMemo, useState } from "react";
import { Alarm } from "src/entities";
import { makeApiUrl } from "src/utils";
import { useData } from "./useData";

type AlarmsApiResponse = {
    events: Alarm[];
    eventsTotal: { [key: string]: number };
};

const useAlarmsInternal = (siteId: string, from: Moment, to: Moment) => {
    const url = makeApiUrl(`/sites/${siteId}/events`, {
        fromDate: moment.utc(from).format(),
        toDate: moment.utc(to).format(),
        offset: 0,
        count: 1000,
        generateVideoUrl: true,
        generateOverlayUrl: true,
    });

    const { data: alarmsResponse, isLoading: alarmsIsLoading, hasError: alarmsHasError } = useData<AlarmsApiResponse>(
        url,
    );

    return { alarmsResponse, alarmsIsLoading, alarmsHasError };
};

export const useAlarms = (_siteId: string, _from: Moment, _to: Moment) => {
    const [siteId] = useState(_siteId);
    const [from, setFrom] = useState(_from);
    const [to, setTo] = useState(_to);

    const { alarmsResponse, alarmsIsLoading, alarmsHasError } = useAlarmsInternal(siteId, from, to);

    const alarms = useMemo(() => {
        return alarmsResponse?.events?.map((event) => ({ ...event }));
    }, [alarmsResponse]);

    const changeDates = useCallback(
        (__from: Moment, __to: Moment) => {
            setFrom(__from);
            setTo(__to);
        },
        [from, to],
    );

    return { alarms, alarmsIsLoading, alarmsHasError, changeDates };
};
