import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Dialog, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faExclamationTriangle, faKey, faPen } from "@fortawesome/pro-solid-svg-icons";
import type { UserProfile } from "entities";
import { SaveDataTransferStatus, TwoFactorAuthMode } from "src/types";
import { useDialogModes } from "src/hooks/useDialogModes";
import { UserProfileSecurityDialog } from "./Dialogs/UserProfileSecurityDialog/UserProfileSecurityDialog";
import type { AvailableSecurityTabs } from "./Types";

type UserProfileSecurityProps = {
    profile: UserProfile;
    updateUser: (profile: UserProfile) => Promise<void>;
    saveProfileStatus: SaveDataTransferStatus;
};

export const UserProfileSecurity = (props: UserProfileSecurityProps) => {
    const [securityDialogIsOpen, setSecurityDialogIsOpen] = useState(false);
    const [securityDialogInitialTab, setSecurityDialogInitialTab] = useState<AvailableSecurityTabs>("ChangePassword");
    const [securityDialogFormDirty, setSecurityDialogFormDirty] = useState(false);

    const { t } = useTranslation();

    const { fullscreen } = useDialogModes();

    const handleEditSecurityButtonClick = (initialTab: AvailableSecurityTabs) => {
        setSecurityDialogInitialTab(initialTab);
        setSecurityDialogIsOpen(true);
    };

    const handleSecurityDialogFormDirty = (isDirty: boolean) => {
        setSecurityDialogFormDirty(isDirty);
    };

    const handleSecurityDialogClose = (event?: any, reason?: "backdropClick" | "escapeKeyDown" | "userCancelled") => {
        if (securityDialogFormDirty && reason !== "userCancelled") {
            return;
        }

        setSecurityDialogIsOpen(false);
    };

    return (
        <div data-testid="user-profile-security">
            <div className="card">
                <div className="data-list data-list--extra-spacing">
                    <div className="data-list__header data-list__header--extra-spacing user-profile-details__header-with-edit">
                        <FontAwesomeIcon icon={faKey} />
                        <div className="user-profile-details__header-text">
                            <h3 className="title title--h3 title--no-spacing">
                                {t("Gui.Common.UserProfileSecurity.SecurityHeader")}
                            </h3>
                        </div>
                        <IconButton
                            className="icon-button"
                            onClick={() => handleEditSecurityButtonClick("ChangePassword")}
                        >
                            <FontAwesomeIcon className="icon-button__icon" icon={faPen} />
                        </IconButton>
                    </div>
                    <div className="data-list__row">
                        <div className="data-list__key">
                            <p className="caption">{t("Gui.Common.UserProfileSecurity.UserVerifiedHeader")}</p>
                        </div>
                        <div className="data-list__line" />
                        <div className="data-list__value">
                            {props.profile?.isUserVerified ? (
                                <p className="paragraph paragraph--small paragraph--bold">
                                    <FontAwesomeIcon className="data-list__value-icon" icon={faCheck} fixedWidth />
                                    {t("Gui.Common.UserProfileSecurity.UserIsVerified")}
                                </p>
                            ) : (
                                <p className="paragraph paragraph--small paragraph--bold">
                                    <FontAwesomeIcon
                                        className="data-list__value-icon data-list__value-icon--warning"
                                        icon={faExclamationTriangle}
                                        fixedWidth
                                    />
                                    {t("Gui.Common.UserProfileSecurity.UserIsNotVerified")}
                                </p>
                            )}
                        </div>
                    </div>
                    <div className="data-list__row">
                        <div className="data-list__key">
                            <p className="caption">{t("Gui.Common.UserProfileSecurity.AccessCodeCreatedHeader")}</p>
                        </div>
                        <div className="data-list__line" />
                        <div className="data-list__value">
                            <p className="paragraph paragraph--small paragraph--bold">
                                {props.profile?.hasAccessCode
                                    ? t("Gui.Common.UserProfileSecurity.AccessCodeCreated")
                                    : t("Gui.Common.UserProfileSecurity.AccessCodeNotCreated")}
                            </p>
                        </div>
                    </div>
                    <div className="data-list__row">
                        <div className="data-list__key">
                            <p className="caption">{t("Gui.Common.UserProfileSecurity.TwoFactorHeader")}</p>
                        </div>
                        <div className="data-list__line" />
                        <div className="data-list__value">
                            {props.profile?.twoFactorAuthentication === TwoFactorAuthMode.GoogleAuthenticator ? (
                                <p className="paragraph paragraph--small paragraph--bold paragraph--no-spacing">
                                    <FontAwesomeIcon className="data-list__value-icon" icon={faCheck} fixedWidth />
                                    {t("Gui.Common.UserProfileSecurity.TwoFactorIsEnabled")}
                                </p>
                            ) : (
                                <React.Fragment>
                                    <p className="paragraph paragraph--small paragraph--bold paragraph--no-spacing">
                                        <FontAwesomeIcon
                                            className="data-list__value-icon data-list__value-icon--warning"
                                            icon={faExclamationTriangle}
                                            fixedWidth
                                        />
                                        {t("Gui.Common.UserProfileSecurity.TwoFactorIsNotEnabled")}
                                    </p>
                                    <p className="caption caption--italic">
                                        {t("Gui.Common.UserProfileSecurity.TwoFactorRecommendedHint")}
                                    </p>
                                </React.Fragment>
                            )}
                        </div>
                    </div>
                    <div className="data-list__row">
                        <div className="data-list__key">
                            <p className="caption">{t("Gui.Common.UserProfileSecurity.AutoLogoutHeader")}</p>
                        </div>
                        <div className="data-list__line" />
                        <div className="data-list__value">
                            <p className="paragraph paragraph--small paragraph--bold">
                                {props.profile?.autoLogoutMinutes > 0
                                    ? `${props.profile?.autoLogoutMinutes} ${t(
                                          "Gui.Common.UserProfileSecurity.Minutes",
                                      )}`
                                    : t("Gui.Common.UserProfileSecurity.AutoLogoutNotActivated")}
                            </p>
                        </div>
                    </div>
                </div>

                <Dialog
                    open={securityDialogIsOpen}
                    fullScreen={fullscreen}
                    fullWidth
                    maxWidth="md"
                    onClose={handleSecurityDialogClose}
                >
                    <UserProfileSecurityDialog
                        profile={props.profile}
                        updateUser={props.updateUser}
                        saveProfileStatus={props.saveProfileStatus}
                        initialTab={securityDialogInitialTab}
                        onChange={handleSecurityDialogFormDirty}
                        onClose={handleSecurityDialogClose}
                    />
                </Dialog>
            </div>
        </div>
    );
};
