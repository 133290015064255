import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";

import { Profile, SaveDataTransferStatus } from "src/types";
import { UserProfileSecurityDialogPassword } from "./UserProfileSecurityDialogPassword";
import { UserProfileSecurityDialogAccessCode } from "./UserProfileSecurityDialogAccessCode";
import { UserProfileSecurityDialogTwoFactor } from "./UserProfileSecurityDialogTwoFactor";
import { Tabs } from "../Tabs";
import type { AvailableSecurityTabs } from "../../Types";

import "../_user-profile-dialog.scss";

type UserProfileSecurityDialogProps = {
    profile: Profile;
    updateUser: (profile: Profile) => Promise<void>;
    saveProfileStatus: SaveDataTransferStatus;
    initialTab: AvailableSecurityTabs;
    onChange: (isDirty: boolean) => void;
    onClose: (event?: any, reason?: "backdropClick" | "escapeKeyDown" | "userCancelled") => void;
};

export const UserProfileSecurityDialog = (props: UserProfileSecurityDialogProps) => {
    const { initialTab, saveProfileStatus } = props;
    const [isDirty, setIsDirty] = useState(false);
    const [showRevertInformation, setShowRevertInformation] = useState(false);

    const { t } = useTranslation();

    const TabTitles: { [K in AvailableSecurityTabs]: string } = {
        ChangePassword: t(`Gui.Common.UserProfileSecurityDialog.ChangePasswordTabName`),
        ChangeAccessCode: t(`Gui.Common.UserProfileSecurityDialog.ChangeAccessCodeTabName`),
        ChangeTwoFactor: t(`Gui.Common.UserProfileSecurityDialog.ChangeTwoFactorTabName`),
    };

    const handleChangeTab = () => {
        if (isDirty) {
            setShowRevertInformation(true);
        }
    };

    const handleOnChange = (isFormDirty: boolean) => {
        if (showRevertInformation) {
            setShowRevertInformation(isFormDirty);
        }

        setIsDirty(isFormDirty);
        props.onChange(isFormDirty);
    };

    return (
        <>
            <div className="card dialog user-profile-dialog">
                <div className="dialog__back-button">
                    <IconButton size="small" edge="start" onClick={(e) => props.onClose(e, "userCancelled")}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </IconButton>
                </div>
                <div className="dialog__content">
                    <div className="dialog__section dialog__section--full">
                        <h1 className="title title-h1">{t("Gui.Common.UserProfileSecurityDialog.MainHeader")}</h1>
                    </div>
                    <Tabs<AvailableSecurityTabs>
                        initialValue={initialTab}
                        titles={TabTitles}
                        disableSwitching={isDirty}
                        handleChangeTab={handleChangeTab}
                    >
                        {(currentTab) => (
                            <>
                                {currentTab === "ChangePassword" ? (
                                    <UserProfileSecurityDialogPassword
                                        profile={props.profile}
                                        updateUser={props.updateUser}
                                        saveProfileStatus={saveProfileStatus}
                                        onChange={handleOnChange}
                                        showRevertInformation={showRevertInformation}
                                    />
                                ) : null}
                                {currentTab === "ChangeAccessCode" ? (
                                    <UserProfileSecurityDialogAccessCode
                                        profile={props.profile}
                                        updateUser={props.updateUser}
                                        saveProfileStatus={saveProfileStatus}
                                        onChange={handleOnChange}
                                        showRevertInformation={showRevertInformation}
                                    />
                                ) : null}
                                {currentTab === "ChangeTwoFactor" ? (
                                    <UserProfileSecurityDialogTwoFactor
                                        profile={props.profile}
                                        updateUser={props.updateUser}
                                        saveProfileStatus={saveProfileStatus}
                                        onChange={handleOnChange}
                                        showRevertInformation={showRevertInformation}
                                    />
                                ) : null}
                            </>
                        )}
                    </Tabs>
                </div>
                <div className="dialog__footer">
                    <Button
                        data-testid="close-dialog-button"
                        disabled={saveProfileStatus.isLoading}
                        type="button"
                        variant="text"
                        color="default"
                        size="medium"
                        onClick={(e) => props.onClose(e, "userCancelled")}
                    >
                        {t("Gui.Buttons.Close")}
                    </Button>
                </div>
            </div>
        </>
    );
};
