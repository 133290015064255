import React, { useState } from "react";
import cx from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faChevronRight } from "@fortawesome/pro-regular-svg-icons";

import "./_tabs-nav.scss";

type TabsProps<T extends string> = {
    initialValue: T;
    titles: { [K in T]: string };
    disableSwitching: boolean;
    handleChangeTab: () => void;
    children(tab: T): JSX.Element;
};

export const Tabs = <T extends string>({
    initialValue,
    titles,
    disableSwitching,
    handleChangeTab,
    children,
}: TabsProps<T>) => {
    const [activeTab, setActiveTab] = useState<T>(initialValue);

    return (
        <>
            <div className="tabs-nav dialog__section dialog__section--small">
                {Object.keys(titles)
                    .map((key) => key as T)
                    .map((tab) => (
                        <div
                            key={tab}
                            className={cx([
                                "tabs-nav__row",
                                {
                                    "tabs-nav__row--active": activeTab === tab,
                                },
                            ])}
                        >
                            <button
                                className="tabs-nav__link"
                                type="button"
                                onClick={() => {
                                    if (!disableSwitching) setActiveTab(tab);
                                    handleChangeTab();
                                }}
                            >
                                <span className="paragraph paragraph--no-spacing">{titles[tab]}</span>
                                <FontAwesomeIcon icon={faChevronRight} />
                            </button>
                        </div>
                    ))}
            </div>
            <div className="dialog__section dialog__section--large dialog__section--flipped-spacing">
                {children(activeTab)}
            </div>
        </>
    );
};
