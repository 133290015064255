import { apiUrl } from "../settings";
import { getToken } from "./storage";

export const getApiRequestHeaders = () => {
    const token = getToken();

    return {
        headers: {
            Authorization: `Bearer ${token ? token.accessToken : ""}`,
        },
    };
};

export const dataApiRequestHeaders = () => {
    const token = getToken();

    return {
        headers: {
            Authorization: `Bearer ${token?.accessToken ?? ""}`,
            "Content-Type": "application/json",
        },
    };
};

type QueryParams = { [key: string]: string | number | boolean };
/**
 * Builds a query string from an object
 * @param params object to convert into query string
 * @returns query string without ?
 */
export const queryParams = (params: QueryParams): string =>
    Object.keys(params)
        .filter((key) => params[key])
        .map((key) => `${key}=${encodeURI(params[key] as string)}`)
        .join("&");

export const makeApiUrl = (path: string, params?: QueryParams): string =>
    params ? `${apiUrl}${path}?${queryParams(params)}` : `${apiUrl}${path}`;

export const getSensorImageUrl = (sensorId: string) =>
    makeApiUrl("/sensorImage", {
        sensorId: encodeURI(sensorId),
    });

export const getImageUrl = (path: string) => makeApiUrl(path);
