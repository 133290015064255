import React, { Component } from "react";
import { createStyles, Theme, withStyles, WithStyles, Typography, Box } from "@material-ui/core";
import { withTranslation, WithTranslation } from "react-i18next";

const styles = () => createStyles({});

type OwnProps = {
    theme: Theme;
    video: string;
    onLoadedMetadata: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
    onLoadedData: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
    onLoadStart: (event: React.SyntheticEvent<HTMLVideoElement, Event>) => void;
};

type Props = OwnProps & WithStyles<typeof styles> & WithTranslation;

class AlarmVideoInternal extends Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = {};
    }

    handleLoadedMetaData = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        this.props.onLoadedMetadata(event);
    };

    handleLoadedData = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        this.props.onLoadedData(event);
    };

    handleLoadStart = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        this.props.onLoadStart(event);
    };

    render() {
        const { t } = this.props;

        return (
            <React.Fragment>
                {this.props.video ? (
                    <video
                        width="100%"
                        height="100%"
                        key={this.props.video}
                        autoPlay={true}
                        controls={true}
                        onLoadedMetadata={this.handleLoadedMetaData}
                        onLoadedData={this.handleLoadedData}
                        onLoadStart={this.handleLoadStart}
                    >
                        <source src={this.props.video} type="video/mp4" />
                        {t("Entities.VideoNotFound")}
                    </video>
                ) : (
                    <Box width="100%" height="100%" pt={10}>
                        <Typography color="textSecondary" align="center" variant="h6">
                            {t("Entities.VideoNotFound")}
                        </Typography>
                    </Box>
                )}
            </React.Fragment>
        );
    }
}

export const AlarmVideo = withTranslation()(withStyles(styles, { withTheme: true })(AlarmVideoInternal));
