import { ScheduleExceptionDelete, ScheduleExceptionPost } from "src/entities";
import { apiUrl } from "src/settings";
import { fetchWithIntercept } from "./fetchService";

export const addScheduleException = async (
    siteId: string,
    scheduleException: ScheduleExceptionPost,
    logout: () => void,
) => {
    const url = `${apiUrl}/sites/${siteId}/scheduleExceptions`;

    try {
        const response = await fetchWithIntercept(url, "POST", logout, scheduleException);

        // we are disregarding the response here and only return true or false,
        // and thus put the resposibility on the function calling this function
        // to refresh scheduleException data from the api

        return response.ok;
    } catch (err) {
        throw new Error(err as string);
    }
};

export const updateScheduleException = async (
    siteId: string,
    scheduleException: ScheduleExceptionDelete,
    logout: () => void,
) => {
    const url = `${apiUrl}/sites/${siteId}/scheduleExceptions`;

    try {
        const response = await fetchWithIntercept(url, "PATCH", logout, scheduleException);

        // we are disregarding the response here and only return true or false,
        // and thus put the resposibility on the function calling this function
        // to refresh scheduleException data from the api

        return response.ok;
    } catch (err) {
        throw new Error(err as string);
    }
};
