import { Observable, of } from "rxjs";
import { map, catchError } from "rxjs/operators";
import { ajax } from "rxjs/ajax";

import { getIrStore } from "./storage";

export const fetchImageWithAccessToken = (url: string): Observable<{ url: string; error: boolean }> => {
    const { accessToken } = getIrStore().get("token");
    return ajax({
        headers: {
            Authorization: `Bearer ${accessToken}`,
        },
        url,
        responseType: "arraybuffer",
    }).pipe(
        map((imageGetResponse) => {
            const blob = new Blob([imageGetResponse.response], {
                type: imageGetResponse.xhr.getResponseHeader("content-type") || undefined,
            });
            return { url: URL.createObjectURL(blob), error: false };
        }),
        catchError(() => {
            return of({ url: "", error: true });
        }),
    );
};
