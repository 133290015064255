import React from "react";

type Props = {
    instruction: string;
};

export const Instructions = (props: Props) => (
    <div className="card card--nested">
        {props.instruction?.split("\n").map((text, index) => (
            <p key={`${index}`} className="paragraph">
                {text}
            </p>
        ))}
    </div>
);
