import { useState, useEffect } from "react";
import { useAuth } from "src/contexts/AuthStateContext";
import { fetchWithIntercept } from "src/services/fetchService";

export const useData = <T extends unknown>(url: string | undefined) => {
    const { logout } = useAuth();
    const [data, setData] = useState<T>();
    const [isLoading, setIsLoading] = useState(false);
    const [hasError, setHasError] = useState(false);

    const abortController = new AbortController();

    const fetchData = async (_url: string) => {
        try {
            setHasError(false);
            setIsLoading(true);

            const response = await fetchWithIntercept(_url, "GET", logout, undefined, abortController);
            const jsonResponse = await response.json();

            if (!response.ok) {
                throw new Error(jsonResponse.error);
            }

            setData(jsonResponse as T);
            setIsLoading(false);
        } catch (err) {
            setHasError(true);
        }
    };

    const refreshData = () => {
        if (url) {
            fetchData(url).catch(() => {
                setHasError(true);
            });
        }
    };

    useEffect(() => {
        if (url) {
            fetchData(url).catch(() => {
                setHasError(true);
            });
        }

        return () => {
            if (abortController) {
                abortController.abort();
            }
        };
    }, [url]);

    return { data, isLoading, hasError, refreshData };
};
