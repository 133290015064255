export const isValidName = (value: string | undefined) => {
    if (value) {
        return true;
    }
    return false;
};

export const isValidPhoneNumber = (value: string | undefined) => {
    if (value && value.match(/^\+(?:[(]?([0-9])[)]?[- ]?){6,14}[0-9]$/)) {
        return true;
    }
    return false;
};

export const isValidPassword = (value: string) => {
    if (value && value.length >= 6) {
        return true;
    }
    return false;
};

export const isValidAccessCodeLength = (value: string) => {
    if (value && value.length === 6) {
        return true;
    }
    return false;
};

export const isAlphanumerical = (value: string) => {
    if (value && value.match(/^[a-zA-Z0-9]*$/)) {
        return true;
    }
    return false;
};
