import React, { FunctionComponent, useEffect, useState } from "react";
import useDebounce from "src/hooks/useDebounce";
import { GlobalSearchContext, GlobalSearchContextType } from "./GlobalSearchContext";

// eslint-disable-next-line react/prop-types
export const GlobalSearchContextProvider: FunctionComponent = ({ children }) => {
    const [term, setTerm] = useState("");
    const [debounceTerm, setDebounceTerm] = useState("");

    const debouncedSearch = useDebounce(debounceTerm, 300);

    useEffect(() => {
        setTerm(debounceTerm);
    }, [debouncedSearch]);

    const value: GlobalSearchContextType = {
        globalSearchTerm: term,
        debounceTerm,
        setGlobalSearchTerm: (t: string) => setDebounceTerm(t.toLocaleLowerCase()),
        clearGlobalSearchTerm: () => setTerm(""),
    };

    return <GlobalSearchContext.Provider value={value}>{children}</GlobalSearchContext.Provider>;
};
