import React from "react";
import { useTranslation } from "react-i18next";
import { Card, CardContent, Typography } from "@material-ui/core/";
import moment from "moment-timezone";
import { formatDateTime } from "../../../utils";

type OwnProps = {
    videoStream: any;
    functionalities: any;
    timeZone: string;
};

export const SiteOverviewVideoStream = (props: OwnProps) => {
    const { t } = useTranslation();
    const { videoStream, timeZone } = props;
    let ratio = "100%";
    if (videoStream.images.latest) {
        ratio = `${(videoStream.images.latest.height / videoStream.images.latest.width) * 100}%`;
    }
    return (
        <div className="video-stream-list__item">
            <Card>
                <CardContent className="video-stream-list__content">
                    <div className="video-stream-list__item-text-container">
                        <Typography variant="body2">{videoStream.siteEquipmentName}</Typography>
                        {videoStream.latestImageUpdateStatus === "Success" ? (
                            <Typography className="video-stream-list__item-text" variant="caption">
                                {`${t("Gui.Common.LastUpdated")} ${formatDateTime(
                                    moment(videoStream.dtLastImageUpdate),
                                    timeZone,
                                )}`}
                            </Typography>
                        ) : null}
                        {videoStream.latestImageUpdateStatus === "NotUploaded" ? (
                            <Typography className="video-stream-list__item-text" variant="caption">
                                {t("Gui.Manage.SystemTesting.NoImageUpdate")}
                            </Typography>
                        ) : null}
                    </div>
                    <div className="video-stream-list__content-image">
                        <div className="video-stream-list__item-image" style={{ paddingTop: ratio }}>
                            <img src={videoStream.images.latest.url} alt={videoStream.siteEquipmentName} />
                            <svg
                                viewBox={`0 0 ${videoStream.images.latest.width} ${videoStream.images.latest.height}`}
                                xmlns="http://www.w3.org/2000/svg"
                                className="alarm-zone-img"
                            >
                                {videoStream.alarmZones?.map((alarmZone, alarmZoneIndex) => {
                                    const { hexColor } = alarmZone.configurationGroup;
                                    const coords = alarmZone.polygon.map((polygon, polyIndex) => {
                                        const points = `${polygon.x * videoStream.images.latest.width},${
                                            polygon.y * videoStream.images.latest.height
                                        }`;
                                        return polyIndex === 0 ? `M${points}` : `L${points}`;
                                    });
                                    return (
                                        <g
                                            key={`${alarmZone.id}-${alarmZoneIndex}`}
                                            fill={`${hexColor}`}
                                            fillOpacity="0.3"
                                            stroke={`${hexColor}`}
                                            strokeOpacity="0.7"
                                            strokeWidth={1}
                                        >
                                            <path d={`${coords.join(" ")} Z`} vectorEffect="non-scaling-stroke" />
                                        </g>
                                    );
                                })}
                            </svg>
                        </div>
                    </div>
                </CardContent>
            </Card>
        </div>
    );
};
