import React, { useEffect, useState } from "react";
import { faBars, faSearch, faHome, faDoorClosed, faUser } from "@fortawesome/pro-light-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Link, useHistory, useLocation } from "react-router-dom";

import {
    AppBar,
    Divider,
    IconButton,
    InputBase,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Menu,
    MenuItem,
    SwipeableDrawer,
    Toolbar,
} from "@material-ui/core";

import { useTranslation } from "react-i18next";
import { ListItemLink } from "src/components";
import logo from "src/img/logos/irisity_logo_header.png";
import { useAuth } from "src/contexts/AuthStateContext";
import { useGlobalSearch } from "src/contexts/GlobalSearchContext";
import { useUserProfile } from "src/contexts/UserProfileContext";

import "./NavBar.scss";

export const NavBar = () => {
    const { auth, logout } = useAuth();
    const { userProfile } = useUserProfile();
    const { clearGlobalSearchTerm, debounceTerm, globalSearchTerm, setGlobalSearchTerm } = useGlobalSearch();

    const { t } = useTranslation();
    const location = useLocation();
    const history = useHistory();

    const [menuIsOpen, setMenuIsOpen] = useState(false);
    const [drawerIsOpen, setDrawerIsOpen] = useState(false);

    const toggleDrawerOpen = (open: boolean) => (event: React.KeyboardEvent | React.MouseEvent) => {
        if (
            event &&
            event.type === "keydown" &&
            ((event as React.KeyboardEvent).key === "Tab" || (event as React.KeyboardEvent).key === "Shift")
        ) {
            return;
        }

        if (drawerIsOpen !== open) {
            setDrawerIsOpen(open);
        }
    };

    const sideList = () => (
        <div
            className="nav-bar__side-list"
            role="presentation"
            onClick={toggleDrawerOpen(false)}
            onKeyDown={toggleDrawerOpen(false)}
        >
            <List>
                <ListItemLink to="/Dashboard">
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faHome} size="2x" />
                    </ListItemIcon>
                    <ListItemText primary={t("Gui.Manage.SideMenu.Overview")} />
                </ListItemLink>
            </List>
            <List>
                <ListItemLink to="/UserProfile">
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faUser} size="2x" />
                    </ListItemIcon>
                    <ListItemText primary={userProfile?.displayName} />
                </ListItemLink>
            </List>
            <Divider />
            <List>
                <ListItem button onClick={logout}>
                    <ListItemIcon>
                        <FontAwesomeIcon icon={faDoorClosed} size="2x" />
                    </ListItemIcon>
                    <ListItemText primary={t("BeforeLogin.Header.Logout")} />
                </ListItem>
            </List>
        </div>
    );

    const handleMenuClose = () => {
        setMenuIsOpen(false);
    };

    useEffect(() => {
        if (globalSearchTerm !== "" && location.pathname !== "/Dashboard") {
            history.push("/Dashboard");
        }
    }, [globalSearchTerm]);

    useEffect(() => {
        if (location.pathname !== "/Dashboard" && globalSearchTerm !== "") {
            setGlobalSearchTerm("");
            clearGlobalSearchTerm();
        }
    }, [location.pathname]);

    return auth ? (
        <div className="nav-bar">
            <AppBar position="static">
                <Toolbar>
                    <Link to="/Dashboard" className="nav-bar__logo">
                        <img src={logo} alt="Irisity" />
                    </Link>
                    <div className="nav-bar__search">
                        <div className="nav-bar__search-icon">
                            <FontAwesomeIcon icon={faSearch} />
                        </div>
                        <InputBase
                            placeholder={t("Gui.Common.FilterPlaceholder")}
                            classes={{
                                root: "nav-bar__search-input-root",
                                input: "nav-bar__search-input-input",
                            }}
                            inputProps={{ "aria-label": "search" }}
                            value={debounceTerm}
                            onChange={(e) => setGlobalSearchTerm(e.target.value)}
                        />
                    </div>
                    <div className="nav-bar__divider"></div>
                    <div className="nav-bar__menu-button">
                        <IconButton
                            edge="start"
                            className="nav-bar__menu-button"
                            color="inherit"
                            aria-label="open drawer"
                            onClick={toggleDrawerOpen(true)}
                        >
                            <FontAwesomeIcon icon={faBars} />
                        </IconButton>
                    </div>
                </Toolbar>
            </AppBar>
            <Menu
                anchorOrigin={{ vertical: "top", horizontal: "right" }}
                keepMounted={true}
                transformOrigin={{ vertical: "top", horizontal: "right" }}
                open={menuIsOpen}
                onClose={handleMenuClose}
            >
                <MenuItem onClick={handleMenuClose}>My account</MenuItem>
                <MenuItem onClick={handleMenuClose}>Profile</MenuItem>
            </Menu>
            <SwipeableDrawer
                anchor="right"
                open={drawerIsOpen}
                onClose={toggleDrawerOpen(false)}
                onOpen={toggleDrawerOpen(true)}
            >
                {sideList()}
            </SwipeableDrawer>
        </div>
    ) : null;
};
