import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, CircularProgress, IconButton, TextField } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndoAlt as faUndoAltRegular } from "@fortawesome/pro-regular-svg-icons";
import {
    faCheck as faCheckSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { Profile, SaveDataTransferStatus } from "src/types";
import { isValidPhoneNumber, isValidName } from "../ValidationFunctions";

type UserProfileIntroDialogDetailsProps = {
    profile: Profile;
    updateUser: (profile: Profile) => Promise<void>;
    saveProfileStatus: SaveDataTransferStatus;
    onChange: (isDirty: boolean) => void;
    isDataLoading: boolean;
    showRevertInformation: boolean;
};

export const UserProfileIntroDialogDetails = (props: UserProfileIntroDialogDetailsProps) => {
    const [originalProfile, setOriginalProfile] = useState({
        fullName: props.profile.fullName,
        displayName: props.profile.displayName,
        email: props.profile.email,
        phoneNumber: props.profile.phoneNumber || "",
        smsNumber: props.profile.smsNumber || "",
    });
    const [isDirty, setIsDirty] = useState(false);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const [fullName, setFullName] = useState<string | undefined>(props.profile.fullName || undefined);
    const [fullNameValid, setFullNameValid] = useState(true);
    const [displayName, setDisplayName] = useState<string | undefined>(props.profile.displayName || undefined);
    const [email, setEmail] = useState<string | undefined>(props.profile.email || undefined);
    const [phoneNumber, setPhoneNumber] = useState<string>(props.profile.phoneNumber || "");
    const [phoneNumberValid, setPhoneNumberValid] = useState(true);
    const [smsNumber, setSmsNumber] = useState<string>(props.profile.smsNumber || "");
    const [smsNumberValid, setSmsNumberValid] = useState(true);

    const { isDataLoading, saveProfileStatus, showRevertInformation } = props;

    const { t } = useTranslation();

    const handleIsDirty = () => {
        let result = false;

        if (
            originalProfile.fullName !== fullName ||
            originalProfile.displayName !== displayName ||
            originalProfile.email !== email ||
            originalProfile.phoneNumber !== phoneNumber ||
            originalProfile.smsNumber !== smsNumber
        ) {
            result = true;
        }

        setIsDirty(result);
        return result;
    };

    const handleRevertChanges = () => {
        setFullName(originalProfile.fullName);
        setDisplayName(originalProfile.displayName);
        setEmail(originalProfile.email);
        setPhoneNumber(originalProfile.phoneNumber);
        setSmsNumber(originalProfile.smsNumber);
    };

    const validateFullName = (value: string | undefined) => {
        const valid = isValidName(value);
        setFullNameValid(valid);
        return valid;
    };

    const validatePhoneNumber = (value: string | undefined) => {
        const valid = !value || isValidPhoneNumber(value);
        setPhoneNumberValid(valid);
        return valid;
    };

    const validateSmsNumber = (value: string | undefined) => {
        const valid = !value || isValidPhoneNumber(value);
        setSmsNumberValid(valid);
        return valid;
    };

    const validateForm = () => {
        let valid = true;
        if (!validateFullName(fullName) || !validatePhoneNumber(phoneNumber) || !validateSmsNumber(smsNumber)) {
            valid = false;
        }

        return valid;
    };

    const saveProfile = () => {
        if (validateForm()) {
            const updatedProfile = {
                fullName,
                displayName,
                email,
                phoneNumber: phoneNumber || null,
                smsNumber: smsNumber || null,
            } as Profile;

            props
                .updateUser(updatedProfile)
                .then(() => {
                    setSaveSuccessful(true);
                })
                .catch(() => {
                    setSaveSuccessful(false);
                });
        }
    };

    useEffect(() => {
        setOriginalProfile({
            fullName: props.profile.fullName,
            displayName: props.profile.displayName,
            email: props.profile.email,
            phoneNumber: props.profile.phoneNumber || "",
            smsNumber: props.profile.smsNumber || "",
        });
    }, [props.profile]);

    useEffect(() => {
        const isDirtyResult = handleIsDirty();
        props.onChange(isDirtyResult);
    }, [originalProfile]);

    useEffect(() => {
        const isDirtyResult = handleIsDirty();
        if (isDirtyResult) setSaveSuccessful(false);
        props.onChange(isDirtyResult);
    }, [fullName, displayName, email, phoneNumber, smsNumber]);

    return (
        <>
            <div className="user-profile-details__header-with-loader">
                <h3 className="title title-h3">{t("Gui.Common.UserProfileIntroDialog.DetailsTabHeader")}</h3>
                {isDataLoading ? (
                    <CircularProgress data-testid="details-form-loading-data" className="spinner" />
                ) : null}
            </div>
            {showRevertInformation ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--multirow notice--warning">
                        <div className="notice__row">
                            <p className="caption caption--alt">
                                {t("Gui.Common.UserProfileDialog.UnsavedChangesSwitchTabsWarning")}
                            </p>
                        </div>
                        <div className="notice__row notice__row--last">
                            <p className="paragraph paragraph--small paragraph--bold paragraph--alt">
                                {t("Gui.Common.UserProfileDialog.UnsavedChangesRevert")}
                                <IconButton
                                    color="inherit"
                                    data-testid="details-revert-button"
                                    className="notice__icon-button"
                                    size="small"
                                    onClick={handleRevertChanges}
                                >
                                    <FontAwesomeIcon className="icon-button__icon" icon={faUndoAltRegular} />
                                </IconButton>
                            </p>
                        </div>
                    </div>
                </div>
            ) : null}
            {saveProfileStatus.hasError ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--critical">
                        <FontAwesomeIcon className="notice__icon" fixedWidth icon={faExclamationTriangleSolid} />
                        <p className="caption">{t("Error.ErrorSaving")}</p>
                    </div>
                </div>
            ) : null}
            {!saveProfileStatus.hasError && !saveProfileStatus.isLoading && saveSuccessful ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--approve">
                        <FontAwesomeIcon className="notice__icon" fixedWidth icon={faCheckSolid} />
                        <p className="caption">{t("Gui.Common.UserProfileIntroDialog.DetailsSavedSuccessfully")}</p>
                    </div>
                </div>
            ) : null}
            <form
                data-testid="dialog-details-tab"
                className="form__section form__section--full form__section--alt form__section--column"
            >
                <div className="form__section">
                    <label className="caption caption--small-spacing" htmlFor="fullName">
                        {t("Gui.Common.UserProfileIntroDialog.FullName")}
                    </label>
                    <TextField
                        inputProps={{ "data-testid": "fullName" }}
                        id="fullName"
                        required
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="text"
                        autoComplete="off"
                        disabled={isDataLoading}
                        value={fullName}
                        helperText={!fullNameValid ? t("Validation.UserProfileIntroDialog.FullName") : null}
                        error={!fullNameValid}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setFullName(e.target.value);
                            validateFullName(e.target.value);
                        }}
                    />
                </div>
                <div className="form__section">
                    <label className="caption caption--small-spacing" htmlFor="displayName">
                        {t("Gui.Common.UserProfileIntroDialog.DisplayName")}
                    </label>
                    <TextField
                        inputProps={{ "data-testid": "displayName" }}
                        id="displayName"
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="text"
                        autoComplete="off"
                        disabled={isDataLoading}
                        value={displayName}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setDisplayName(e.target.value);
                        }}
                    />
                </div>
                <div className="form__section">
                    <label className="caption caption--small-spacing" htmlFor="email">
                        {t("Gui.Common.UserProfileIntroDialog.Email")}
                    </label>
                    <TextField
                        id="email"
                        required
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="text"
                        autoComplete="off"
                        disabled
                        value={email}
                    />
                </div>
                <div className="form__section">
                    <label className="caption caption--small-spacing" htmlFor="phoneNumber">
                        {t("Gui.Common.UserProfileIntroDialog.PhoneNumber")}
                    </label>
                    <TextField
                        inputProps={{ "data-testid": "phoneNumber" }}
                        id="phoneNumber"
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="text"
                        autoComplete="off"
                        disabled={isDataLoading}
                        value={phoneNumber}
                        helperText={!phoneNumberValid ? t("Validation.UserProfileIntroDialog.PhoneNumber") : null}
                        error={!phoneNumberValid}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setPhoneNumber(e.target.value);
                            validatePhoneNumber(e.target.value);
                        }}
                    />
                </div>
                <div className="form__section">
                    <label className="caption caption--small-spacing" htmlFor="smsNumber">
                        {t("Gui.Common.UserProfileIntroDialog.SmsNumber")}
                    </label>
                    <TextField
                        inputProps={{ "data-testid": "smsNumber" }}
                        id="smsNumber"
                        fullWidth
                        variant="outlined"
                        size="small"
                        type="text"
                        autoComplete="off"
                        disabled={isDataLoading}
                        value={smsNumber}
                        helperText={!smsNumberValid ? t("Validation.UserProfileIntroDialog.SmsNumber") : null}
                        error={!smsNumberValid}
                        onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                            setSmsNumber(e.target.value);
                            validateSmsNumber(e.target.value);
                        }}
                    />
                </div>
                <div className="dialog__section dialog__section--buttons">
                    <Button
                        data-testid="save-details-button"
                        disabled={saveProfileStatus.isLoading || isDataLoading || !isDirty}
                        type="button"
                        color="primary"
                        variant="contained"
                        size="small"
                        onClick={saveProfile}
                    >
                        {t("Gui.Buttons.SaveChanges")}
                    </Button>
                </div>
            </form>
        </>
    );
};
