import { dataApiRequestHeaders, getApiRequestHeaders } from "src/utils/request";

export const fetchWithIntercept = (
    url: string,
    method: string,
    logout: () => void,
    body?: any,
    abortController?: AbortController,
    anonymousRequest = false,
) => {
    let requestInit: RequestInit = anonymousRequest ? { method } : { method, ...getApiRequestHeaders() };

    if (body) {
        requestInit = { ...requestInit, ...dataApiRequestHeaders() };
        requestInit.body = JSON.stringify(body);
    }

    if (abortController) {
        requestInit = { ...requestInit, signal: abortController.signal };
    }

    return fetch(url, requestInit).then((response) => {
        if (!response.ok) {
            if ([401, 403].includes(response.status)) {
                // auto logout if 401 Unauthorized or 403 Forbidden response returned from api
                logout();
            }
        }

        return response;
    });
};
