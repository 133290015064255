import { Skeleton } from "@material-ui/lab";
import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import { useUserProfile } from "src/contexts/UserProfileContext";
import { useLocales } from "src/hooks/useLocale";
import { UserProfileIntro } from "./UserProfileIntro";
import { UserProfileSecurity } from "./UserProfileSecurity";

import "./_user-profile-details.scss";

export const UserProfileDetails = () => {
    const { saveProfileStatus, userProfile, updateUserProfile } = useUserProfile();
    const { languages, timeZones, locales, getLocalizationStatus } = useLocales();

    const { t } = useTranslation();

    const renderComponents = useCallback(() => {
        let returnComponent;

        if (getLocalizationStatus.isLoading) {
            returnComponent = (
                <>
                    <Skeleton variant="rect" height={400} style={{ marginBottom: 20, maxWidth: 600 }} />
                    <Skeleton variant="rect" height={400} style={{ maxWidth: 600 }} />
                </>
            );
        } else if (getLocalizationStatus.hasError) {
            returnComponent = t("Error.ErrorLoading");
        } else if (userProfile && languages && locales && timeZones) {
            returnComponent = (
                <>
                    <div className="user-profile-details__section">
                        <UserProfileIntro
                            profile={userProfile}
                            languages={languages}
                            locales={locales}
                            timeZones={timeZones}
                            updateUser={updateUserProfile}
                            saveProfileStatus={saveProfileStatus}
                            getLocalizationStatus={getLocalizationStatus}
                        />
                    </div>
                    <div className="user-profile-details__section">
                        <UserProfileSecurity
                            profile={userProfile}
                            updateUser={updateUserProfile}
                            saveProfileStatus={saveProfileStatus}
                        />
                    </div>
                </>
            );
        } else {
            returnComponent = t("Error.ErrorLoading");
        }

        return returnComponent;
    }, [userProfile, languages, locales, timeZones, saveProfileStatus, updateUserProfile, getLocalizationStatus]);

    return (
        <div data-testid="user-profile-details" className="user-profile-details">
            {renderComponents()}
        </div>
    );
};
