import "react-app-polyfill/ie11";

/* eslint-disable import/no-extraneous-dependencies */
import "core-js/es/array";
import "core-js/es/string";
import "core-js/es/object";
import "core-js/es/function/name";
import "core-js/es/number";
/* eslint-enable import/no-extraneous-dependencies */
import React, { Suspense } from "react";
import ReactDOM from "react-dom";
import { Router } from "react-router-dom";
import { createBrowserHistory } from "history";
/* eslint-disable import/no-extraneous-dependencies */
import moment from "moment-timezone";
import "moment/locale/nb";
import "moment/locale/sv";
/* eslint-enable import/no-extraneous-dependencies */
import { CssBaseline, LinearProgress } from "@material-ui/core";
import { MuiThemeProvider, StylesProvider } from "@material-ui/core/styles";
import { App } from "./components";
import * as serviceWorker from "./serviceWorker";
import { initLanguageHandling } from "./utils/i18n";
import { getUserLanguage, getUserLocale } from "./utils/storage";
import { AuthStateContextProvider } from "./contexts/AuthStateContextProvider";
import { GlobalSearchContextProvider } from "./contexts/GlobalSearchContextProvider";

import "./styles/app.scss";
import "typeface-roboto";
import { theme } from "./styles/theme";
import { UserProfileContextProvider } from "./contexts/UserProfileContextProvider";

initLanguageHandling(getUserLanguage());
moment.locale(getUserLocale());

export const history = createBrowserHistory();

ReactDOM.render(
    <React.Fragment>
        <Router history={history}>
            <StylesProvider injectFirst>
                <MuiThemeProvider theme={theme}>
                    <CssBaseline />
                    <Suspense fallback={<LinearProgress />}>
                        <GlobalSearchContextProvider>
                            <AuthStateContextProvider>
                                <UserProfileContextProvider>
                                    <App />
                                </UserProfileContextProvider>
                            </AuthStateContextProvider>
                        </GlobalSearchContextProvider>
                    </Suspense>
                </MuiThemeProvider>
            </StylesProvider>
        </Router>
    </React.Fragment>,
    document.getElementById("root"),
);

// if you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
