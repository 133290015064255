import { createContext, useContext } from "react";
import { LoginDetails, LoginRequest } from "src/types";

type Credentials = Omit<LoginRequest, "accessToken">;

export type AuthStateContextType = {
    auth: LoginDetails | undefined;
    authError: boolean;
    isLoggedIn: boolean;
    requiresTwoFactorAuth: boolean;
    twoFactorStatusError: boolean;
    checkTwoFactorStatus: (email: string) => void;
    login: (credentials: Credentials) => Promise<void>;
    logout: () => void;
};

export const AuthStateContext = createContext<Partial<AuthStateContextType>>({});

export function useAuth() {
    return useContext(AuthStateContext) as AuthStateContextType;
}
