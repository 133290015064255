import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { apiUrl } from "src/settings";
import { GetDataTransferStatus, IrMap, Language } from "src/types";
import { useData } from "./useData";

const useLanguages = () => {
    const url = `${apiUrl}/profile/languages`;
    const { data: languagesResponse, isLoading: languagesIsLoading, hasError: languagesHasError } = useData<Language[]>(
        url,
    );
    return { languagesResponse, languagesIsLoading, languagesHasError };
};

const useTimeZones = () => {
    const url = `${apiUrl}/timeZones`;
    const { data: timeZonesResponse, isLoading: timeZonesIsLoading, hasError: timeZonesHasError } = useData<string[]>(
        url,
    );
    return { timeZonesResponse, timeZonesIsLoading, timeZonesHasError };
};

const useLocalesInternal = () => {
    const url = `${apiUrl}/locales`;
    const { data: localesResponse, isLoading: localesIsLoading, hasError: localesHasError } = useData<string[]>(url);
    return { localesResponse, localesIsLoading, localesHasError };
};

export const useLocales = () => {
    const [languages, setLanguages] = useState<Language[]>();
    const [timeZones, setTimeZones] = useState<IrMap<string, string>>();
    const [locales, setLocales] = useState<string[]>();
    const [getLocalizationStatus, setGetLocalizationStatus] = useState<GetDataTransferStatus>({
        isLoading: true,
        hasError: false,
    });

    const { t } = useTranslation();
    const { languagesResponse, languagesHasError } = useLanguages();
    const { timeZonesResponse, timeZonesHasError } = useTimeZones();
    const { localesResponse, localesHasError } = useLocalesInternal();

    useEffect(() => {
        if (languagesResponse) {
            setLanguages(languagesResponse);
        }
    }, [languagesResponse]);

    useEffect(() => {
        if (timeZonesResponse) {
            const timeZonesDictionary = new IrMap<string, string>();

            timeZonesResponse.forEach((tz: string) => {
                const translatedTz = t(`Entities.TimeZone.${tz}`);
                timeZonesDictionary.push({ key: tz, value: translatedTz });
            });

            setTimeZones(timeZonesDictionary);
        }
    }, [timeZonesResponse]);

    useEffect(() => {
        if (localesResponse) {
            setLocales(localesResponse);
        }
    }, [localesResponse]);

    useEffect(() => {
        if (languages && timeZones && locales) {
            setGetLocalizationStatus({
                isLoading: false,
                hasError: false,
            });
        } else if (languagesHasError || timeZonesHasError || localesHasError) {
            setGetLocalizationStatus({
                isLoading: false,
                hasError: true,
            });
        }
    }, [languages, timeZones, locales]);

    return { languages, timeZones, locales, getLocalizationStatus };
};
