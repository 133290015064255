import React, { Component } from "react";
import { withTranslation, WithTranslation } from "react-i18next";
import { AlarmVideo } from "src/components";
import { AlarmOverlay, AlarmZoneType } from "src/entities";

import "./_alarm-overlay.scss";

type OwnProps = {
    video: string;
    overlay: AlarmOverlay;
};

type Props = OwnProps & WithTranslation;

class AlarmOverlayVideoInternal extends Component<Props, any> {
    constructor(props: Props) {
        super(props);
        this.state = {
            width: null,
            height: null,
            videoDataLoaded: null,
            error: null,
            overlay: null,
        };
    }

    handleLoadedMetadata = (event: React.SyntheticEvent<HTMLVideoElement, Event>) => {
        this.setState({
            width: (event.target as HTMLVideoElement).videoWidth,
            height: (event.target as HTMLVideoElement).videoHeight,
        });
    };

    handleLoadedData = () => {
        this.setState({
            videoDataLoaded: true,
        });
    };

    handleLoadStart = () => {
        this.setState({
            videoDataLoaded: false,
        });
    };

    renderOverlay = () => {
        return this.props.overlay && this.state.width && this.state.height && this.state.videoDataLoaded;
    };

    relativeToSvg = (x: number, y: number) => {
        if ((x || x === 0) && (y || y === 0) && this.state.width && this.state.height) {
            const X = this.state.width > 1 ? x * (this.state.width - 1) : x;
            const Y = this.state.height > 1 ? y * (this.state.height - 1) : y;
            return `${X},${Y}`;
        }
        return null;
    };

    absoluteToSvg = (x: number, y: number) => {
        if ((x || x === 0) && (y || y === 0)) {
            return `${x},${y}`;
        }
        return null;
    };

    alarmZonePaths = () => {
        const paths = this.props.overlay?.alarmZones?.flatMap((alarmZone, alarmZoneIndex) => {
            if (alarmZone) {
                const coords = alarmZone.polygon?.flatMap((vertex, vertexIndex) => {
                    const points = this.relativeToSvg(vertex?.x, vertex?.y);
                    if (points) {
                        return vertexIndex === 0 ? `M${points}` : `L${points}`;
                    }
                    return [];
                });
                if (coords?.length && coords[0].startsWith("M")) {
                    return (
                        <path
                            vectorEffect="non-scaling-stroke"
                            key={`${alarmZoneIndex}-${alarmZone.id}`}
                            d={`${coords.join(" ")} Z`}
                        />
                    );
                }
                return [];
            }
            return [];
        });
        return paths?.length ? paths : null;
    };

    triggeredAlarmZonePath = () => {
        const coords = this.props.overlay?.triggeredAlarmZone?.polygon?.flatMap((vertex, vertexIndex) => {
            const points = this.relativeToSvg(vertex?.x, vertex?.y);
            if (points) {
                return vertexIndex === 0 ? `M${points}` : `L${points}`;
            }
            return [];
        });
        if (coords?.length && coords[0].startsWith("M")) {
            return <path vectorEffect="non-scaling-stroke" d={`${coords.join(" ")} Z`} />;
        }
        return null;
    };

    getBoundingBoxCoords = (x: number, y: number, width: number, height: number, offset: number) => {
        if ((x || x === 0) && (y || y === 0) && width && height && (offset || offset === 0)) {
            const resized = [
                {
                    x: x - width / 2,
                    y: y - height / 2,
                },
                {
                    x: x + (3 * width) / 2,
                    y: y - height / 2,
                },
                {
                    x: x + (3 * width) / 2,
                    y: y + (3 * height) / 2,
                },
                {
                    x: x - width / 2,
                    y: y + (3 * height) / 2,
                },
            ].flatMap((vertex, vertexIndex) => {
                if (vertex) {
                    const points = this.absoluteToSvg(vertex?.x + offset, vertex?.y + offset);
                    if (points) {
                        return vertexIndex === 0 ? `M${points}` : `L${points}`;
                    }
                }
                return [];
            });
            if (resized?.length && resized[0].startsWith("M")) {
                return resized;
            }
            return null;
        }
        return null;
    };

    blobHistoryPath = () => {
        const coords = this.props.overlay?.blobPositionHistory?.flatMap((vertex, vertexIndex) => {
            const points = this.absoluteToSvg(vertex?.x, vertex?.y);
            if (points) {
                return vertexIndex === 0 ? `M${points}` : `L${points}`;
            }
            return [];
        });
        if (coords?.length && coords[0].startsWith("M")) {
            return <path vectorEffect="non-scaling-stroke" d={`${coords.join(" ")}`} />;
        }
        return null;
    };

    boundingBoxPaths = (offset: number) => {
        const paths = this.props.overlay?.boundingBoxes?.flatMap((bbox, index) => {
            if (bbox) {
                const coords = this.getBoundingBoxCoords(bbox?.x, bbox?.y, bbox?.width, bbox?.height, offset);
                if (coords?.length && coords[0].startsWith("M")) {
                    return coords ? (
                        <path vectorEffect="non-scaling-stroke" key={index} d={`${coords.join(" ")} Z`} />
                    ) : null;
                }
                return [];
            }
            return [];
        });
        return paths?.length ? paths : null;
    };

    render() {
        let overlay;

        if (this.renderOverlay()) {
            const triggeredAlarmZonePath = this.triggeredAlarmZonePath();
            const alarmZonePaths = this.alarmZonePaths();
            let triggeredAlarmZone;
            let alarmZones;
            let boundingBoxes;
            let dropShadows;
            let blobHistory;
            const boundingBoxPaths = this.boundingBoxPaths(0);
            const dropShadowPaths = this.boundingBoxPaths(-1);
            const blobHistoryPath = this.blobHistoryPath();

            if (
                this.props.overlay?.triggeredAlarmZone?.type === AlarmZoneType[AlarmZoneType.MotionDetection] ||
                this.props.overlay?.triggeredAlarmZone?.type ===
                    AlarmZoneType[AlarmZoneType.ViolentBehaviorDetection] ||
                this.props.overlay?.triggeredAlarmZone?.type === AlarmZoneType[AlarmZoneType.FallDetection]
            ) {
                boundingBoxes = boundingBoxPaths ? (
                    <g className="alarm-overlay__overlay alarm-overlay__overlay--path">{boundingBoxPaths}</g>
                ) : null;
            }

            switch (this.props.overlay?.triggeredAlarmZone?.type) {
                case AlarmZoneType.MotionDetection:
                    triggeredAlarmZone = triggeredAlarmZonePath ? (
                        <g className="alarm-overlay__overlay alarm-overlay__overlay--triggered">
                            {triggeredAlarmZonePath}
                        </g>
                    ) : null;
                    alarmZones = alarmZonePaths ? <g className="alarm-overlay__overlay">{alarmZonePaths}</g> : null;
                    dropShadows = dropShadowPaths ? (
                        <g className="alarm-overlay__overlay alarm-overlay__overlay--drop-shadow">{dropShadowPaths}</g>
                    ) : null;
                    blobHistory = blobHistoryPath ? (
                        <g className="alarm-overlay__overlay alarm-overlay__overlay--path">{blobHistoryPath}</g>
                    ) : null;

                    overlay = (
                        <svg
                            viewBox={`0 0 ${this.state.width} ${this.state.height}`}
                            xmlns="http://www.w3.org/2000/svg"
                            className="alarm-overlay__svg"
                        >
                            {triggeredAlarmZone}
                            {alarmZones}
                            {boundingBoxes}
                            {dropShadows}
                            {blobHistory}
                        </svg>
                    );
                    break;
                case AlarmZoneType.ViolentBehaviorDetection:
                case AlarmZoneType.FallDetection:
                    triggeredAlarmZone = triggeredAlarmZonePath ? (
                        <g className="alarm-overlay__overlay alarm-overlay__overlay--triggered">
                            {triggeredAlarmZonePath}
                        </g>
                    ) : null;
                    alarmZones = alarmZonePaths ? <g className="alarm-overlay__overlay">{alarmZonePaths}</g> : null;
                    dropShadows = dropShadowPaths ? (
                        <g className="alarm-overlay__overlay alarm-overlay__overlay--drop-shadow">{dropShadowPaths}</g>
                    ) : null;

                    overlay = (
                        <svg
                            viewBox={`0 0 ${this.state.width} ${this.state.height}`}
                            xmlns="http://www.w3.org/2000/svg"
                            className="alarm-overlay__svg"
                        >
                            {triggeredAlarmZone}
                            {alarmZones}
                            {boundingBoxes}
                            {dropShadows}
                        </svg>
                    );
                    break;
                case AlarmZoneType.LoiteringDetection:
                    triggeredAlarmZone = triggeredAlarmZonePath ? (
                        <g className="alarm-overlay__overlay alarm-overlay__overlay--triggered">
                            {triggeredAlarmZonePath}
                        </g>
                    ) : null;
                    alarmZones = alarmZonePaths ? <g className="alarm-overlay__overlay">{alarmZonePaths}</g> : null;

                    overlay = (
                        <svg
                            viewBox={`0 0 ${this.state.width} ${this.state.height}`}
                            xmlns="http://www.w3.org/2000/svg"
                            className="alarm-overlay__svg"
                        >
                            {triggeredAlarmZone}
                            {alarmZones}
                        </svg>
                    );
                    break;
                default:
                    if (this.props.overlay?.boundingBoxes) {
                        boundingBoxes = boundingBoxPaths ? (
                            <g className="alarm-overlay__overlay alarm-overlay__overlay--bounding">
                                {boundingBoxPaths}
                            </g>
                        ) : null;
                        dropShadows = dropShadowPaths ? (
                            <g className="alarm-overlay__overlay alarm-overlay__overlay--drop-shadow">
                                {dropShadowPaths}
                            </g>
                        ) : null;
                        overlay = (
                            <svg
                                viewBox={`0 0 ${this.state.width} ${this.state.height}`}
                                xmlns="http://www.w3.org/2000/svg"
                                className={"alarm-overlay__svg"}
                            >
                                {boundingBoxes}
                                {dropShadows}
                            </svg>
                        );
                    } else {
                        overlay = null;
                    }
                    break;
            }
        } else {
            overlay = null;
        }

        return (
            <div className="alarm-overlay">
                <AlarmVideo
                    video={this.props.video}
                    onLoadedMetadata={this.handleLoadedMetadata}
                    onLoadedData={this.handleLoadedData}
                    onLoadStart={this.handleLoadStart}
                ></AlarmVideo>
                {overlay}
            </div>
        );
    }
}

export const AlarmOverlayVideo = withTranslation()(AlarmOverlayVideoInternal);
