import React from "react";

import animationMp4 from "./irisity-logo-animation.mp4";
import animationOgv from "./irisity-logo-animation.ogv";
import posterImage from "./irisity-logo-animation.png";
import animationWebm from "./irisity-logo-animation.webm";

export const LogoAnimation: React.FC = () => (
    <video
        style={{ width: "100%", maxWidth: "1080px", objectPosition: "50% 50%", overflow: "hidden" }}
        autoPlay={true}
        className="logo-animation"
        poster={posterImage}
    >
        <source src={animationWebm} type="video/webm" />
        <source src={animationMp4} type="video/mp4" />
        <source src={animationOgv} type="video/ogg" />
        <img src="./irisity-logo.jpg" alt="Placeholder since html5 video is not supported" />
    </video>
);
