import React, { useEffect, useState } from "react";

import { Divider, Tabs, LinearProgress, Fade } from "@material-ui/core/";
import { useTranslation } from "react-i18next";
import { Link, Redirect, RouteComponentProps, Switch } from "react-router-dom";

import { Site } from "src/entities";
import { useSiteDetails } from "src/hooks/useSiteDetails";
import { SiteAlarms } from "./SiteAlarms";
import { LinkTab, PropsRoute } from "../Core";
import { SiteOverview } from "./SiteOverview";

import "./SiteDetails.scss";

export const SiteDetails = (props: RouteComponentProps) => {
    const { location, match } = props;
    const [siteId] = useState((match.params as any).id);
    const [site, setSite] = useState<Site | undefined>(undefined);
    const [currentUrl] = useState(match.url);

    const { siteDetails, siteDetailsIsLoading, siteDetailsHasError } = useSiteDetails({ siteId });

    const { t } = useTranslation();

    const getActiveRoute = () => {
        let route = currentUrl;

        if (location.pathname.startsWith(`${route}/alarms`)) {
            route += "/alarms";
        }

        return route;
    };

    const [activeRoute, setActiveRoute] = useState(getActiveRoute());

    useEffect(() => setSite(siteDetails?.site), [siteDetails]);

    useEffect(() => {
        setActiveRoute(getActiveRoute());
    }, [match]);

    return (
        <div className="site-details">
            <ul className="breadcrumbs">
                <li>
                    <Link to="/Dashboard">{t("Gui.Manage.SideMenu.Overview")}</Link>
                </li>
                <li>{site?.name}</li>
            </ul>
            <Divider light={true} />
            <Fade in={!site} timeout={{ enter: 700, exit: 400 }}>
                <LinearProgress />
            </Fade>
            <Tabs className="site-details__tab" value={activeRoute}>
                <LinkTab label={t("Entities.Site.Site")} to={`${currentUrl}`} value={`${currentUrl}`} />
                <LinkTab
                    label={t("Entities.Events.Alarms")}
                    to={`${currentUrl}/alarms`}
                    value={`${currentUrl}/alarms`}
                />
            </Tabs>
            {siteDetailsHasError ? (
                <div className="site-details__notice notice notice--critical">{t("Error.Loading.Site")}</div>
            ) : null}
            {site ? (
                <Switch>
                    <PropsRoute
                        path={`${currentUrl}`}
                        exact={true}
                        component={SiteOverview}
                        withProps={{ site, siteDetails, siteDetailsIsLoading }}
                    />
                    <PropsRoute path={`${currentUrl}/alarms`} component={SiteAlarms} withProps={{ site, test: 1 }} />
                    <Redirect to={`${currentUrl}`} />
                </Switch>
            ) : null}
        </div>
    );
};
