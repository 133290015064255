import React, { useEffect, useState } from "react";
import {
    Box,
    Button,
    Select,
    MenuItem,
    ButtonGroup,
    Divider,
    Typography,
    LinearProgress,
    Snackbar,
    Checkbox,
    IconButton,
    Switch,
    FormControlLabel,
    InputLabel,
    FormControl,
} from "@material-ui/core/";
import { faArrowLeft, faInfoCircle } from "@fortawesome/pro-light-svg-icons";
import { faCheck, faEye, faEyeSlash } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import moment, { Moment } from "moment-timezone";
import { DateTimePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import MomentUtils from "@date-io/moment";
import { useAuth } from "src/contexts/AuthStateContext";
import { ScheduleException, ScheduleExceptionDelete, ScheduleExceptionPost } from "../../../entities/ScheduleException";
import { ScheduleExceptionsTable } from "./ScheduleExceptionsTable";
import { Duration, hasCollisions, hasDatesInPast, hasInvalidEndDate } from "./siteExceptionsUtils";
import { OkCancelDialog } from "../../Core";
import { Site } from "../../../entities";
import { useScheduleExceptions } from "../../../hooks/useScheduleExceptions";

import "./SiteExceptions.scss";

type Props = {
    site: Site;
    onClose(): void;
};

export const SiteExceptions = ({ site, onClose }: Props) => {
    const [duration, setDuration] = useState<Duration>("1Hour");
    const [exceptionEnabled, setExceptionEnabled] = useState(false);
    const [advancedMode, setAdvancedMode] = useState(false);
    const [showHistorical, setShowHistorical] = useState(false);
    const [dtStart, setDtStart] = useState(moment.utc().tz(site.timeZone));
    const [dtStop, setDtStop] = useState(moment.utc().tz(site.timeZone).add(1, "hour"));
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showOverlapConfirmation, setShowOverlapConfirmation] = useState(false);

    const [scheduleExceptionsOngoing, setScheduleExceptionsOngoing] = useState<ScheduleException[]>([]);
    const [scheduleExceptionsUpcoming, setScheduleExceptionsUpcoming] = useState<ScheduleException[]>([]);
    const [scheduleExceptionsHistorical, setScheduleExceptionsHistorical] = useState<ScheduleException[]>([]);

    const { logout } = useAuth();
    const { t } = useTranslation();
    const {
        scheduleExceptions,
        scheduleExceptionsIsLoading,
        scheduleExceptionsPostIsLoading,
        scheduleExceptionsDeleteIsLoading,
        postException,
        deleteException,
    } = useScheduleExceptions({ siteId: site.id, logout });

    const handleEndOrRemoveExceptionClick = async (ex: ScheduleException) => {
        const submitData: ScheduleExceptionDelete = {
            scheduleExceptionId: ex.id,
        };

        await deleteException(submitData);
    };

    const onApply = async (allowOverride = false) => {
        let start: Moment;
        let end: Moment;
        const utcNow = moment.utc();
        let ex = {
            id: "",
            isOnType: exceptionEnabled,
        } as ScheduleExceptionPost;

        if (!advancedMode) {
            start = utcNow.clone();
            end = moment(start).add(1, "hours");
            switch (duration) {
                case "30Minutes":
                    end = moment(start).add(30, "minutes");
                    break;
                case "1Hour":
                    end = moment(start).add(1, "hours");
                    break;
                case "2Hours":
                    end = moment(start).add(2, "hours");
                    break;
                case "3Hours":
                    end = moment(start).add(3, "hours");
                    break;
                case "4Hours":
                    end = moment(start).add(4, "hours");
                    break;
                case "6Hours":
                    end = moment(start).add(6, "hours");
                    break;
                case "8Hours":
                    end = moment(start).add(8, "hours");
                    break;
                case "12Hours":
                    end = moment(start).add(12, "hours");
                    break;
                case "16Hours":
                    end = moment(start).add(16, "hours");
                    break;
                case "24Hours":
                    end = moment(start).add(24, "hours");
                    break;
                default:
                    break;
            }
            ex = { ...ex, durationSeconds: moment.duration(end.diff(start)).asSeconds() } as ScheduleExceptionPost;
        } else {
            start = dtStart.clone().utc();
            end = dtStop.clone().utc();
            ex = { ...ex, dtStart: start, dtStop: end, isOnType: exceptionEnabled } as ScheduleExceptionPost;
        }

        const collisions = hasCollisions(scheduleExceptions, start, end);
        const datesInPast = hasDatesInPast(start, end, utcNow);
        const invalidEndDate = hasInvalidEndDate(start, end);

        if (datesInPast) {
            setShowErrorMessage(true);
            setErrorMessage("Validation.irDateFuture");
        } else if (invalidEndDate) {
            setShowErrorMessage(true);
            setErrorMessage("Validation.irDateEndIsBeforeStart");
        } else if (collisions && !allowOverride) {
            setShowOverlapConfirmation(true);
            setErrorMessage("Entities.ScheduleException.OverlappingExceptionsMessage");
        } else {
            if (allowOverride) {
                ex.overrideExistingExceptions = true;
            }
            await postException(ex);
        }
    };

    const isLoading = () => {
        return scheduleExceptionsIsLoading || scheduleExceptionsPostIsLoading || scheduleExceptionsDeleteIsLoading;
    };

    const handleOverrideDialogClosed = async (okClicked: boolean) => {
        setShowOverlapConfirmation(false);
        if (okClicked) {
            await onApply(true);
        }
    };

    const setScheduleExceptionArrays = () => {
        const now = moment.utc();

        const ongoing = scheduleExceptions?.filter((ex: ScheduleException) =>
            now.isBetween(ex.dtStart, ex.dtStop, "minutes", "[)"),
        );
        // sort ongoing on dtStop desc - so that the exception that is closer to ending will be on top
        setScheduleExceptionsOngoing(ongoing.sort((a, b) => b.dtStop.diff(a.dtStop)));

        const upcoming = scheduleExceptions?.filter((ex: ScheduleException) => now.isBefore(ex.dtStart, "minutes"));
        // sort upcoming on dtStart asc - so that the exception that is closer to starting will be on the top
        setScheduleExceptionsUpcoming(upcoming.sort((a, b) => a.dtStart.diff(b.dtStart)));

        const historical = scheduleExceptions?.filter((ex: ScheduleException) =>
            now.isSameOrAfter(ex.dtStop, "minutes"),
        );
        // sort historical on dtStop desc - so that the most recently ended exception is on top
        setScheduleExceptionsHistorical(historical.sort((a, b) => b.dtStop.diff(a.dtStart)));
    };

    useEffect(() => {
        if (!scheduleExceptionsIsLoading && !scheduleExceptionsPostIsLoading && !scheduleExceptionsDeleteIsLoading) {
            setScheduleExceptionArrays();
        }
    }, [scheduleExceptionsIsLoading, scheduleExceptionsPostIsLoading, scheduleExceptionsDeleteIsLoading]);

    return (
        <React.Fragment>
            <div className="card dialog site-exceptions">
                <Snackbar
                    className="site-exceptions__error"
                    onClose={() => {
                        setShowErrorMessage(false);
                        setErrorMessage("");
                    }}
                    open={showErrorMessage}
                    message={t(errorMessage)}
                    autoHideDuration={3000}
                ></Snackbar>

                <OkCancelDialog
                    title={t("Entities.ScheduleException.OverlapOverrideConfirmationTitle")}
                    onClose={async (ok: boolean) => {
                        await handleOverrideDialogClosed(ok);
                    }}
                    okButtonText="Gui.Buttons.Override"
                    open={showOverlapConfirmation}
                >
                    {t("Entities.ScheduleException.OverlapOverrideConfirmationBody")}
                </OkCancelDialog>

                {isLoading() ? <LinearProgress /> : null}

                <div className="dialog__back-button">
                    <IconButton size="small" edge="start" onClick={onClose}>
                        <FontAwesomeIcon icon={faArrowLeft} />
                    </IconButton>
                </div>

                <div className="dialog__content">
                    <div className="dialog__section dialog__section--full">
                        <Typography variant="h6" className="site-exceptions__section" gutterBottom>
                            {t("Entities.ScheduleException.CreateNew")}
                        </Typography>
                    </div>

                    <div className="dialog__section dialog__section--full">
                        <Typography variant="body2" gutterBottom={true}>
                            {t("Entities.ScheduleException.AnalysisShouldBe")}
                        </Typography>
                        <ButtonGroup fullWidth={true} className="site-exceptions__radio-buttons" variant="outlined">
                            <Button
                                onClick={() => setExceptionEnabled(true)}
                                className={
                                    exceptionEnabled
                                        ? "site-exceptions__radio-button--selected"
                                        : "site-exceptions__radio-button"
                                }
                                startIcon={<FontAwesomeIcon icon={faEye} />}
                            >
                                {" "}
                                {t("Entities.ScheduleException.Enabled")}{" "}
                            </Button>
                            <Button
                                onClick={() => setExceptionEnabled(false)}
                                className={
                                    !exceptionEnabled
                                        ? "site-exceptions__radio-button--selected"
                                        : "site-exceptions__radio-button"
                                }
                                startIcon={<FontAwesomeIcon icon={faEyeSlash} />}
                            >
                                {" "}
                                {t("Entities.ScheduleException.Disabled")}{" "}
                            </Button>
                        </ButtonGroup>
                    </div>
                    {!advancedMode ? (
                        <div className="dialog__section site-exceptions__period-picker">
                            {/* <div className="caption">{t("Entities.ScheduleException.StartingNow")}</div> */}
                            <FormControl className="site-exceptions__duration-picker">
                                <InputLabel id="select-exception-duration-label">
                                    {t("Entities.ScheduleException.StartingNow")}
                                </InputLabel>
                                <Select
                                    labelId="select-exception-duration-label"
                                    id="select-exception-duration"
                                    fullWidth={true}
                                    onChange={(e) => {
                                        setDuration(e.target.value as Duration);
                                    }}
                                    value={duration}
                                >
                                    <MenuItem value={"30Minutes"}>
                                        {t("Entities.ScheduleException.MinutesFormat", { count: 30 })}
                                    </MenuItem>
                                    <MenuItem value={"1Hour"}>
                                        {t("Entities.ScheduleException.HoursFormat", { count: 1 })}
                                    </MenuItem>
                                    <MenuItem value={"2Hours"}>
                                        {t("Entities.ScheduleException.HoursFormat", { count: 2 })}
                                    </MenuItem>
                                    <MenuItem value={"3Hours"}>
                                        {t("Entities.ScheduleException.HoursFormat", { count: 3 })}
                                    </MenuItem>
                                    <MenuItem value={"4Hours"}>
                                        {t("Entities.ScheduleException.HoursFormat", { count: 4 })}
                                    </MenuItem>
                                    <MenuItem value={"6Hours"}>
                                        {t("Entities.ScheduleException.HoursFormat", { count: 6 })}
                                    </MenuItem>
                                    <MenuItem value={"8Hours"}>
                                        {t("Entities.ScheduleException.HoursFormat", { count: 8 })}
                                    </MenuItem>
                                    <MenuItem value={"12Hours"}>
                                        {t("Entities.ScheduleException.HoursFormat", { count: 12 })}
                                    </MenuItem>
                                    <MenuItem value={"16Hours"}>
                                        {t("Entities.ScheduleException.HoursFormat", { count: 16 })}
                                    </MenuItem>
                                    <MenuItem value={"24Hours"}>
                                        {t("Entities.ScheduleException.HoursFormat", { count: 24 })}
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    ) : (
                        <div className="dialog__section site-exceptions__period-picker">
                            <MuiPickersUtilsProvider utils={MomentUtils}>
                                <Box mx={1} width="100%">
                                    <Typography variant="body2">{t("Entities.Schedule.StartTime")}</Typography>
                                    <DateTimePicker
                                        value={dtStart}
                                        ampm={false}
                                        clearable={true}
                                        disablePast={true}
                                        onChange={(dateTime) => setDtStart((dateTime as unknown) as Moment)}
                                        onError={() => {}} // TODO: Error handling?
                                        className="site-exceptions__date-time-picker"
                                    />
                                </Box>
                                <Box mx={1} width="100%">
                                    <Typography variant="body2">{t("Entities.Schedule.StopTime")}</Typography>
                                    <DateTimePicker
                                        value={dtStop}
                                        ampm={false}
                                        clearable={true}
                                        disablePast={true}
                                        onChange={(dateTime) => setDtStop((dateTime as unknown) as Moment)}
                                        onError={() => {}}
                                        className="site-exceptions__date-time-picker"
                                    />
                                </Box>
                            </MuiPickersUtilsProvider>
                        </div>
                    )}
                    <div className="dialog__section site-exceptions__button">
                        <Box>
                            <FormControlLabel
                                control={<Checkbox onChange={(e) => setAdvancedMode(e.target.checked)} />}
                                label={t("Entities.ScheduleException.Advanced")}
                            />
                        </Box>
                        <Box>
                            <Button
                                variant="contained"
                                startIcon={<FontAwesomeIcon icon={faCheck} />}
                                color="primary"
                                onClick={async () => {
                                    await onApply(false);
                                }}
                                disabled={isLoading()}
                                size="large"
                            >
                                {t("Gui.Buttons.Apply")}
                            </Button>
                        </Box>
                    </div>
                    <div className="dialog__section dialog__section--full">
                        <Typography variant="h6" className="site-exceptions__section" gutterBottom>
                            {t("Entities.ScheduleException.CurrentScheduleExceptions")}
                        </Typography>
                    </div>

                    <ScheduleExceptionsTable
                        scheduleExceptions={scheduleExceptionsOngoing}
                        analysisEnabledCaption={t("Entities.ScheduleException.AnalysisIs")}
                        onRemove={(exception) => handleEndOrRemoveExceptionClick(exception)}
                        removeButtonCaption={t("Entities.ScheduleException.EndNow")}
                        removeRequestLoading={scheduleExceptionsDeleteIsLoading}
                        site={site}
                        disableRemoveButton={isLoading()}
                    />

                    <div className="dialog__section dialog__section--full">
                        <Typography variant="h6" className="site-exceptions__section" gutterBottom>
                            {t("Entities.ScheduleException.UpcomingScheduleExceptions")}
                        </Typography>
                    </div>

                    <ScheduleExceptionsTable
                        scheduleExceptions={scheduleExceptionsUpcoming}
                        analysisEnabledCaption={t("Entities.ScheduleException.AnalysisWillBe")}
                        onRemove={(exception) => handleEndOrRemoveExceptionClick(exception)}
                        removeButtonCaption={t("Gui.Buttons.Remove")}
                        removeRequestLoading={scheduleExceptionsDeleteIsLoading}
                        site={site}
                        disableRemoveButton={isLoading()}
                    />

                    <div className="dialog__section dialog__section--full">
                        <Typography variant="h6" className="site-exceptions__section">
                            {t("Entities.ScheduleException.HistoricalScheduleExceptions")}
                        </Typography>
                    </div>
                    <FormControlLabel
                        control={
                            <Switch checked={showHistorical} onChange={(e) => setShowHistorical(e.target.checked)} />
                        }
                        label={t("Entities.ScheduleException.ShowHistoricalScheduleExceptions")}
                    />

                    {showHistorical ? (
                        <ScheduleExceptionsTable
                            scheduleExceptions={scheduleExceptionsHistorical}
                            analysisEnabledCaption={t("Entities.ScheduleException.AnalysisWas")}
                            site={site}
                            disableRemoveButton={isLoading()}
                        />
                    ) : null}

                    <Divider />

                    <div className="site-exceptions__info-box">
                        <p className="paragraph paragraph--small">
                            <FontAwesomeIcon icon={faInfoCircle} className="paragraph__icon" fixedWidth />
                            {t("Entities.ScheduleException.OverlapInfo")}
                        </p>
                    </div>
                </div>

                <div className="dialog__footer">
                    <Button variant="outlined" color="primary" onClick={onClose} disabled={isLoading()}>
                        {t("Gui.Buttons.Close")}
                    </Button>
                </div>
            </div>
        </React.Fragment>
    );
};
