import React, { ReactNode } from "react";
import moment from "moment-timezone";
import { RouteComponentProps } from "react-router-dom";

import { useTranslation } from "react-i18next";
import { AlarmOverlayVideo } from "./AlarmOverlayVideo";
import { AlarmThumbnail } from "./AlarmThumbnail";
import { Alarm, Site, EventAssessmentClass, EventClassification } from "../../../entities";

import { formatDateTime } from "../../../utils";

import "./_info-list.scss";
import "./_alarm-details.scss";

type InfoString = {
    isTranslatable: boolean;
    strings: (string | ReactNode)[];
};

type Props = {
    alarm: Alarm;
    site: Site;
} & RouteComponentProps;

export const AlarmDetails = (props: Props) => {
    const { alarm, site } = props;
    const { t } = useTranslation();

    const findInfoString = (result) => {
        if (result.isTranslatable) {
            return t(result.strings);
        }

        return result.strings;
    };

    const getClassificationInfo = () => {
        const result: InfoString = {
            isTranslatable: true,
            strings: [""],
        };
        if (alarm.eventAssessmentClass === EventAssessmentClass[EventAssessmentClass.Unknown]) {
            result.strings = ["Gui.Manage.SiteEventsTable.Details.PendingClassification"];
            return result;
        }

        switch (alarm.eventClassification) {
            case EventClassification[EventClassification.L2HumanActivityNo]:
                // Only visible for sysadmins, so Ok with special string
                result.strings = ["Gui.Manage.SiteEventsTable.Details.L2HumanActivityNo"];
                break;
            case EventClassification[EventClassification.L2HumanActivityUncertain]:
                // Only visible for sysadmins, so Ok with special string
                result.strings = ["Gui.Manage.SiteEventsTable.Details.L2HumanActivityUncertain"];
                break;
            case EventClassification[EventClassification.L2HumanActivityYes]:
                // Visible for everyone, so no special string here.
                // We could show a special string here, like "Gui.Manage.SiteEventsTable.Details.L2HumanActivityYes"
                // only for sysadmins, if possible. But perhaps better to show this distinction in some other way.
                result.strings = ["Gui.Manage.SiteEventsTable.Details.HumanActivity"];
                break;
            case EventClassification[EventClassification.FlameNo]:
                result.strings = ["Gui.Manage.SiteEventsTable.Details.FlameNo"];
                break;
            case EventClassification[EventClassification.FlameYes]:
                result.strings = ["Gui.Manage.SiteEventsTable.Details.FlameYes"];
                break;
            case EventClassification[EventClassification.LoiteringHumanActivityNo]:
                result.strings = ["Gui.Manage.SiteEventsTable.Details.LoiteringHumanActivityNo"];
                break;
            case EventClassification[EventClassification.LoiteringHumanActivityYes]:
                result.strings = ["Gui.Manage.SiteEventsTable.Details.LoiteringHumanActivityYes"];
                break;
            case EventClassification[EventClassification.Human]:
                result.strings = ["Gui.Manage.SiteEventsTable.Details.HumanActivity"];
                break;
            case EventClassification[EventClassification.ViolentBehaviorNo]:
                result.strings = ["Gui.Manage.SiteEventsTable.Details.ViolentBehaviorNo"];
                break;
            case EventClassification[EventClassification.ViolentBehaviorYes]:
                result.strings = ["Gui.Manage.SiteEventsTable.Details.ViolentBehaviorYes"];
                break;
            case EventClassification[EventClassification.FallNo]:
                result.strings = ["Gui.Manage.SiteEventsTable.Details.FallNo"];
                break;
            case EventClassification[EventClassification.FallYes]:
                result.strings = ["Gui.Manage.SiteEventsTable.Details.FallYes"];
                break;
            case EventClassification[EventClassification.RailDefenderNo]:
                result.strings = ["Gui.Manage.SiteEventsTable.Details.RailDefenderNo"];
                break;
            case EventClassification[EventClassification.RailDefenderYes]:
                result.strings = ["Gui.Manage.SiteEventsTable.Details.RailDefenderYes"];
                break;
            case EventClassification[EventClassification.UnattendedObjectNo]:
                result.strings = ["Gui.Manage.SiteEventsTable.Details.UnattendedObjectNo"];
                break;
            case EventClassification[EventClassification.UnattendedObjectYes]:
                result.strings = ["Gui.Manage.SiteEventsTable.Details.UnattendedObjectYes"];
                break;
            default:
                result.strings = ["Gui.Manage.SiteEventsTable.Details.NoHumanActivity"];
                break;
        }

        return result;
    };

    const getEvaluationInfo = () => {
        const result: InfoString = {
            isTranslatable: true,
            strings: [""],
        };
        if (alarm.eventAssessmentClass === EventAssessmentClass[EventAssessmentClass.Unknown]) {
            result.strings = ["Gui.Manage.SiteEventsTable.Details.PendingClassification"];
        } else if (
            alarm.eventAssessmentClass === EventAssessmentClass[EventAssessmentClass.NoAssessmentNeeded] ||
            alarm.eventAssessmentClass === EventAssessmentClass[EventAssessmentClass.NoClassificationNeeded]
        ) {
            result.strings = ["Gui.Manage.SiteEventsTable.Details.NoEvaluationNeeded"];
        } else if (!!alarm.decisionMakingDetails) {
            result.isTranslatable = false;
            result.strings = alarm.decisionMakingDetails
                .split(/\r\n/g)
                .filter((infoString) => infoString !== "" && infoString != null)
                .map((infoString, index) => <div key={index}>{infoString}</div>);
        }
        return result;
    };

    const getMeasureInfo = () => {
        const result: InfoString = {
            isTranslatable: true,
            strings: [""],
        };
        if (alarm.eventAssessmentClass === EventAssessmentClass[EventAssessmentClass.Unknown]) {
            result.strings = ["Gui.Manage.SiteEventsTable.Details.PendingClassification"];
        } else if (alarm.eventAssessmentClass !== EventAssessmentClass[EventAssessmentClass.MeasureTaken]) {
            result.strings = ["Gui.Manage.SiteEventsTable.Details.NoMeasureTaken"];
        } else if (!!alarm.measureTakingDetails) {
            result.isTranslatable = false;
            result.strings = alarm.measureTakingDetails
                .split(/\r\n/g)
                .filter((infoString) => infoString !== "" && infoString != null)
                .map((infoString, index) => <div key={index}>{infoString}</div>);
        }
        return result;
    };

    return (
        <React.Fragment>
            {alarm ? (
                <div className="alarm-details">
                    <div className="alarm-details__video">
                        {alarm?.videoUrl && alarm?.overlay ? (
                            <AlarmOverlayVideo video={alarm.videoUrl} overlay={alarm.overlay}></AlarmOverlayVideo>
                        ) : (
                            <AlarmThumbnail alarm={alarm} />
                        )}
                    </div>
                    <ul className="info-list">
                        <li className="info-list__item">
                            <div className="info-list__header">
                                {alarm.siteEquipmentName}, {alarm.functionalityName}{" "}
                            </div>
                            <div className="info-list__body info-list__body--alt">
                                {formatDateTime(moment.utc(alarm.dtEvent), site.timeZone)}
                            </div>
                        </li>
                        <li className="info-list__item">
                            <div className="info-list__header">
                                {t("Gui.Manage.SiteEventsTable.Details.Header.Classification")}
                            </div>
                            <div className="info-list__body">{findInfoString(getClassificationInfo())}</div>
                        </li>
                        <li className="info-list__item">
                            <div className="info-list__header">
                                {t("Gui.Manage.SiteEventsTable.Details.Header.Evaluation")}
                            </div>
                            <div className="info-list__body">{findInfoString(getEvaluationInfo())}</div>
                        </li>
                        <li className="info-list__item">
                            <div className="info-list__header">
                                {t("Gui.Manage.SiteEventsTable.Details.Header.Measure")}
                            </div>
                            <div className="info-list__body">{findInfoString(getMeasureInfo())}</div>
                        </li>
                        <li className="info-list__item">
                            <div className="info-list__header">{t("Entities.EventReport.EventId")}</div>
                            <div className="info-list__body">{alarm.friendlyId}</div>
                        </li>
                    </ul>
                </div>
            ) : (
                <div>{t("Entities.Alarm.AlarmNotFound")}</div>
            )}
        </React.Fragment>
    );
};
