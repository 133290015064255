import React, { Component } from "react";
import {
    createStyles,
    GridList,
    GridListTile,
    GridListTileBar,
    Theme,
    WithStyles,
    withStyles,
} from "@material-ui/core";
import moment from "moment-timezone";
import { withRouter, RouteComponentProps } from "react-router-dom";
import { WithTranslation, withTranslation } from "react-i18next";
import { Alarm } from "src/entities";
import { apiUrl } from "src/settings";
import { Link, LazyLoadImage } from "src/components";
import { formatDateTime } from "src/utils";

const styles = (theme: Theme) =>
    createStyles({
        root: {
            simpleScroll: {},
            display: "flex",
            width: "100%",
            flexWrap: "wrap",
            justifyContent: "space-around",
            marginBottom: theme.spacing(3),
        },
        gridList: {
            flexWrap: "nowrap",
            width: "100%",
            "*::-webkit-scrollbar-track": {
                "-webkit-box-shadow": "inset 0 0 6px rgba(0,0,0,0.3)",
                borderRadius: "10px",
                backgroundColor: "red !important",
            },
        },
        gridListTile: {
            cursor: "pointer",
        },
        gridListTileInner: {
            width: "160px",
            height: "120px",
            borderRadius: "10px",
            "&.selected": {
                border: "2px solid #fff",
                "& .image": {
                    margin: "-2px",
                },
            },
            "& .image": {
                backgroundPosition: "center top",
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                height: "100%",
            },
        },
    });

type OwnProps = {
    theme: Theme;
    alarms: Alarm[];
    selectedAlarmId?: string;
    timeZone: string;
};

type Props = OwnProps & WithStyles<typeof styles> & RouteComponentProps & WithTranslation;

class AlarmsGridListInternal extends Component<Props> {
    render() {
        const { classes } = this.props;
        const gridRef = React.createRef<HTMLUListElement>();

        return (
            <div className={classes.root}>
                <GridList ref={gridRef} className={classes.gridList} cols={0} cellHeight="auto" spacing={12}>
                    {this.props.alarms.map((alarm: any) => (
                        <GridListTile
                            key={alarm.id}
                            classes={{
                                root: `${classes.gridListTile} stretch-link-boundary`,
                                tile:
                                    classes.gridListTileInner +
                                    (this.props.selectedAlarmId === alarm.id ||
                                    this.props.selectedAlarmId === alarm.friendlyId
                                        ? " selected"
                                        : ""),
                            }}
                        >
                            {/* We should use image after fixing endpoint that returns one frame (or gif animation?) <img class="image" src={apiUrl + "/events/" + alarm.friendlyId + "/video/sprite/thumbnail"} alt={alarm.detector} /> */}
                            <LazyLoadImage
                                src={`${apiUrl}/events/${alarm.friendlyId}/video/sprite/thumbnail`}
                                alt={alarm.friendlyId}
                                intersectionNode={gridRef?.current}
                            />
                            <Link to={`${this.props.match.url}/${alarm.friendlyId}`} className="stretch-link">
                                <GridListTileBar
                                    title={alarm.siteEquipmentName}
                                    subtitle={formatDateTime(moment.utc(alarm.dtEvent), this.props.timeZone)}
                                />
                            </Link>
                        </GridListTile>
                    ))}
                </GridList>
            </div>
        );
    }
}

export const AlarmsGridList = withTranslation()(
    withStyles(styles, { withTheme: true })(withRouter(AlarmsGridListInternal)),
);
