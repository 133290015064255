export enum EventClassification {
    // Must be kept in sync with
    // Mindmancer.Services.Api.Common.RestApi.Shared.Enums.EventClasificationEnum

    Human = 1000,
    Car = 2000,
    Truck = 3000,

    L2HumanActivityYes = 4000,

    Animal = 9000,

    Environment = 10000,
    Tree = 10001,
    Bush = 10002,
    MovingWater = 10003,
    ReflectingWater = 10004,

    Unknown = 15000,

    // Legacy values sent from Mv1 Alarm Central
    // Another classification model would be prefered in the future.
    SunReflection = 20001,
    SwayingObject = 20002,
    Liquid = 20003,
    ShakingCamera = 20004,
    Shadow = 20005,
    MovingCloudShadow = 20006,
    BadlyConfiguredAlarmZone = 20007,
    OtherDetails = 20008,

    L2HumanActivityNo = 30000,
    L2HumanActivityUncertain = 40000,

    // Loitering analysis (says nothing about other, non-loitering human activity)
    LoiteringHumanActivityNo = 50100,
    LoiteringHumanActivityYes = 50102,

    // Reserved for later, but not used yet
    // L2LoiteringHumanActivityNo = 50110,
    // L2LoiteringHumanActivityUncertain = 50111,
    // L2LoiteringHumanActivityYes = 50112,

    // Flame detection (says nothing about human activity)
    FlameNo = 50200,
    FlameYes = 50202,

    // Reserved for later, but not used yet
    // L2FlameNo = 50210,
    // L2FlameUncertain = 50211,
    // L2FlameYes = 50212

    // Violent Behavior analysis
    ViolentBehaviorNo = 50300,
    ViolentBehaviorYes = 50302,

    // Not used yet, reserved for later
    // L2ViolentBehaviorNo = 50310,
    // L2ViolentBehaviorUncertain = 50311,
    // L2ViolentBehaviorYes = 50312

    // Handling issue alarms, for now only acknowledged is implemented.
    IssueAcknowledged = 50402,

    // Rail Defender
    RailDefenderNo = 50500,
    RailDefenderYes = 50502,

    // Not used yet, reserved for later
    // L2RailDefenderNo = 50510,
    // L2RailDefenderUncertain = 50511,
    // L2RailDefenderYes = 50512

    // Unattended object detection
    UnattendedObjectNo = 50600,
    UnattendedObjectYes = 50602,

    // Not used yet, reserved for later
    // L2UnattendedObjectNo = 50610,
    // L2UnattendedObjectUncertain = 50611,
    // L2UnattendedObjectYes = 50612

    // Fall detection
    FallNo = 50700,
    FallYes = 50702,

    // Not used yet, reserved for later
    // L2FallNo = 50710,
    // L2FallUncertain = 50711,
    // L2FallYes = 50712

    // Test alarm
    Test = 100000,
}
