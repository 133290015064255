import { createContext, useContext } from "react";

export type GlobalSearchContextType = {
    globalSearchTerm: string;
    debounceTerm: string;
    setGlobalSearchTerm: (term: string) => void;
    clearGlobalSearchTerm: () => void;
};

export const GlobalSearchContext = createContext<Partial<GlobalSearchContextType>>({});

export function useGlobalSearch() {
    return useContext(GlobalSearchContext) as GlobalSearchContextType;
}
