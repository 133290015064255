import { createMuiTheme, Theme } from "@material-ui/core";
import * as Colors from "@material-ui/core/colors";
import { darken } from "@material-ui/core/styles";

export const theme: Theme = createMuiTheme({
    palette: {
        background: {
            default: Colors.grey[900],
            paper: Colors.grey[800],
        },
        primary: Colors.blue,
        type: "dark",
    },
    overrides: {
        MuiAppBar: {
            colorPrimary: {
                backgroundColor: darken(Colors.grey[800], 0.3),
                color: Colors.grey[400],
            },
        },
        MuiPaper: {
            root: {
                marginBottom: "16px",
            },
        },
    },
});
