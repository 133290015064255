import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, IconButton } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import { faExclamationTriangle } from "@fortawesome/pro-solid-svg-icons";

import type { GetDataTransferStatus, IrMap, Language, Profile, SaveDataTransferStatus } from "src/types";
import { faArrowLeft } from "@fortawesome/pro-light-svg-icons";
import { UserProfileIntroDialogDetails } from "./UserProfileIntroDialogDetails";
import { UserProfileIntroDialogLocale } from "./UserProfileIntroDialogLocale";
import type { AvailableIntroTabs } from "../../Types";
import { Tabs } from "../Tabs";

import "../_user-profile-dialog.scss";

type UserProfileIntroDialogProps = {
    profile: Profile;
    languages: Language[];
    locales: string[];
    timeZones: IrMap<string, string>;
    updateUser: (profile: Profile) => Promise<void>;
    saveProfileStatus: SaveDataTransferStatus;
    getLocalizationStatus: GetDataTransferStatus;
    initialTab: AvailableIntroTabs;
    onChange: (isDirty: boolean) => void;
    onClose: (event?: any, reason?: "backdropClick" | "escapeKeyDown" | "userCancelled") => void;
};

export const UserProfileIntroDialog = (props: UserProfileIntroDialogProps) => {
    const { initialTab, saveProfileStatus, getLocalizationStatus } = props;
    const [isDirty, setIsDirty] = useState(false);
    const [showRevertInformation, setShowRevertInformation] = useState(false);

    const { t } = useTranslation();

    const TabTitles: { [K in AvailableIntroTabs]: string } = {
        DetailsTab: t(`Gui.Common.UserProfileIntroDialog.DetailsTabName`),
        LocaleTab: t(`Gui.Common.UserProfileIntroDialog.LocaleTabName`),
    };

    const handleChangeTab = () => {
        if (isDirty) {
            setShowRevertInformation(true);
        }
    };

    const handleOnChange = (isFormDirty: boolean) => {
        if (showRevertInformation) {
            setShowRevertInformation(isFormDirty);
        }

        setIsDirty(isFormDirty);
        props.onChange(isFormDirty);
    };

    return (
        <>
            {getLocalizationStatus.hasError ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--critical">
                        <FontAwesomeIcon className="notice__icon" fixedWidth icon={faExclamationTriangle} />
                        <p className="caption">{t("Error.ErrorLoading")}</p>
                    </div>
                </div>
            ) : (
                <div className="card dialog user-profile-dialog">
                    <div className="dialog__back-button">
                        <IconButton size="small" edge="start" onClick={(e) => props.onClose(e, "userCancelled")}>
                            <FontAwesomeIcon icon={faArrowLeft} />
                        </IconButton>
                    </div>
                    <div className="dialog__content">
                        <div className="dialog__section dialog__section--full">
                            <h1 className="title title-h1">{t("Gui.Common.UserProfileIntroDialog.MainHeader")}</h1>
                        </div>
                        <Tabs<AvailableIntroTabs>
                            initialValue={initialTab}
                            titles={TabTitles}
                            disableSwitching={isDirty}
                            handleChangeTab={handleChangeTab}
                        >
                            {(currentTab) => (
                                <>
                                    {currentTab === "DetailsTab" ? (
                                        <UserProfileIntroDialogDetails
                                            profile={props.profile}
                                            updateUser={props.updateUser}
                                            saveProfileStatus={props.saveProfileStatus}
                                            onChange={handleOnChange}
                                            isDataLoading={props.getLocalizationStatus.isLoading}
                                            showRevertInformation={showRevertInformation}
                                        />
                                    ) : null}
                                    {currentTab === "LocaleTab" ? (
                                        <UserProfileIntroDialogLocale
                                            profile={props.profile}
                                            languages={props.languages}
                                            locales={props.locales}
                                            timeZones={props.timeZones}
                                            updateUser={props.updateUser}
                                            saveProfileStatus={props.saveProfileStatus}
                                            getLocalizationStatus={props.getLocalizationStatus}
                                            onChange={handleOnChange}
                                            showRevertInformation={showRevertInformation}
                                        />
                                    ) : null}
                                </>
                            )}
                        </Tabs>
                    </div>
                    <div className="dialog__footer">
                        <Button
                            data-testid="close-dialog-button"
                            disabled={saveProfileStatus.isLoading || getLocalizationStatus.isLoading}
                            type="button"
                            variant="text"
                            color="default"
                            size="medium"
                            onClick={(e) => props.onClose(e, "userCancelled")}
                        >
                            {t("Gui.Buttons.Close")}
                        </Button>
                    </div>
                </div>
            )}
        </>
    );
};
