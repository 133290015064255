import { schema } from "normalizr";

const partnerSchemaDef = new schema.Entity("partners", {});

export const alarmSchema = new schema.Entity("alarms", {}, { idAttribute: "friendlyId" });
export const companySchema = new schema.Entity("companies", { partner: partnerSchemaDef });
export const configurationGroupSchema = new schema.Entity("configurationGroup", {});
export const partnerSchema = partnerSchemaDef;
export const scheduleExceptionsSchema = new schema.Entity("scheduleExceptions", {});
export const siteSchema = new schema.Entity("sites", { company: companySchema, partner: partnerSchemaDef });
export const siteStatisticsSchema = new schema.Entity("siteStatistics", {});
export const videoStreamSchema = new schema.Entity("videoStream", {});
