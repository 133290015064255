import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Button, FormControl, FormHelperText, IconButton, InputAdornment, OutlinedInput } from "@material-ui/core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUndoAlt as faUndoAltRegular } from "@fortawesome/pro-regular-svg-icons";
import {
    faCheck as faCheckSolid,
    faExclamationTriangle as faExclamationTriangleSolid,
} from "@fortawesome/pro-solid-svg-icons";
import { faEye as faEyeLight, faEyeSlash as faEyeSlashLight } from "@fortawesome/pro-light-svg-icons";
import { Profile, SaveDataTransferStatus } from "src/types";
import { isValidPassword } from "../ValidationFunctions";

type UserProfileSecurityDialogPasswordProps = {
    profile: Profile;
    updateUser: (profile: Profile) => Promise<void>;
    saveProfileStatus: SaveDataTransferStatus;
    onChange: (isDirty: boolean) => void;
    showRevertInformation: boolean;
};

export const UserProfileSecurityDialogPassword = (props: UserProfileSecurityDialogPasswordProps) => {
    const [originalProfile, setOriginalProfile] = useState<Profile | undefined>(
        props.profile ? { ...props.profile } : undefined,
    );
    const [isDirty, setIsDirty] = useState(false);
    const [saveSuccessful, setSaveSuccessful] = useState(false);
    const [showTwoFactorCodeField] = useState(!!props.profile?.twoFactorAuthentication);
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showCurrentPassword, setShowCurrentPassword] = useState(false);
    const [showNewPasswordValidation, setShowNewPasswordValidation] = useState(false);
    const [newPassword, setNewPassword] = useState("");
    const [newPasswordValid, setNewPasswordValid] = useState(true);
    const [newPasswordMatchesCurrentPassword, setNewPasswordMatchesCurrentPassword] = useState(false);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [confirmPasswordValid, setConfirmPasswordValid] = useState(true);
    const [currentPassword, setCurrentPassword] = useState("");
    const [twoFactorAuthCode, setTwoFactorAuthCode] = useState<string>("");

    const { saveProfileStatus, showRevertInformation } = props;

    const { t } = useTranslation();

    const handleIsDirty = () => {
        const result = newPassword !== "";
        setIsDirty(result);
        return result;
    };

    const handleRevertChanges = () => {
        setNewPassword("");
        setConfirmPassword("");
        setCurrentPassword("");
    };

    const validateNewPassword = () => {
        const valid = isValidPassword(newPassword);
        setNewPasswordValid(valid);
        return valid;
    };

    const validateNewPasswordAtLeastSevenCharacters = (value: string) => {
        let valid = false;
        if (value.length > 6) {
            valid = true;
        }

        return valid;
    };

    const validateNewPasswordAtLeastOneNumber = (value: string) => {
        let valid = false;
        if (value.match(/\d/)) {
            valid = true;
        }

        return valid;
    };

    const validateNewPasswordAtLeastOneSpecialCharacter = (value: string) => {
        let valid = false;
        if (value.match(/[!@#$%^&*(),.?":{}|<>]/)) {
            valid = true;
        }

        return valid;
    };

    const validateConfirmPassword = () => {
        let valid = true;
        if (confirmPassword && confirmPassword !== newPassword) {
            valid = false;
        }

        setConfirmPasswordValid(valid);
        return valid;
    };

    const checkIfNewPasswordMatchesCurrentPassword = () => {
        let valid = false;
        if (currentPassword && newPassword && currentPassword === newPassword) {
            valid = true;
        }

        setNewPasswordMatchesCurrentPassword(valid);
        return valid;
    };

    const validateForm = () => {
        let valid = true;
        if (!validateNewPassword()) {
            valid = false;
        }

        if (checkIfNewPasswordMatchesCurrentPassword()) {
            valid = false;
        }

        return valid;
    };

    const passwordValidationIcon = (valid: boolean) => {
        if (valid) {
            return <FontAwesomeIcon className="data-list__value-icon" icon={faCheckSolid} fixedWidth />;
        }

        return (
            <FontAwesomeIcon
                className="data-list__value-icon data-list__value-icon--warning"
                icon={faExclamationTriangleSolid}
                fixedWidth
            />
        );
    };

    const handleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const handleShowCurrentPassword = () => {
        setShowCurrentPassword(!showCurrentPassword);
    };

    const handleMouseDownPasswordInput = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const saveProfile = () => {
        if (originalProfile !== undefined && validateForm()) {
            const newProfile = {
                ...originalProfile,
                currentPassword,
                newPassword,
                twoFactorAuthCode,
            };

            props
                .updateUser(newProfile)
                .then(() => {
                    setNewPassword("");
                    setConfirmPassword("");
                    setCurrentPassword("");
                    setShowNewPasswordValidation(false);
                    setSaveSuccessful(true);
                })
                .catch(() => {
                    setSaveSuccessful(false);
                });
        }
    };

    useEffect(() => {
        setOriginalProfile(props.profile ? { ...props.profile } : undefined);
    }, [props.profile]);

    useEffect(() => {
        const isDirtyResult = handleIsDirty();
        props.onChange(isDirtyResult);
    }, [originalProfile]);

    useEffect(() => {
        validateNewPassword();
        if (newPassword) setSaveSuccessful(false);
        const isDirtyResult = handleIsDirty();
        props.onChange(isDirtyResult);
    }, [newPassword]);

    useEffect(() => {
        validateConfirmPassword();
    }, [newPassword, confirmPassword]);

    useEffect(() => {
        checkIfNewPasswordMatchesCurrentPassword();
    }, [newPassword, currentPassword]);

    return (
        <>
            <h3 className="title title-h3">{t("Gui.Common.UserProfileSecurityDialog.ChangePasswordTabHeader")}</h3>
            {showRevertInformation ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--multirow notice--warning">
                        <div className="notice__row">
                            <p className="caption caption--alt">
                                {t("Gui.Common.UserProfileDialog.UnsavedChangesSwitchTabsWarning")}
                            </p>
                        </div>
                        <div className="notice__row notice__row--last">
                            <p className="paragraph paragraph--small paragraph--bold paragraph--alt">
                                {t("Gui.Common.UserProfileDialog.UnsavedChangesRevert")}
                                <IconButton
                                    data-testid="password-revert-button"
                                    className="notice__icon-button"
                                    size="small"
                                    onClick={handleRevertChanges}
                                >
                                    <FontAwesomeIcon className="icon-button__icon" icon={faUndoAltRegular} />
                                </IconButton>
                            </p>
                        </div>
                    </div>
                </div>
            ) : null}
            {saveProfileStatus.hasError ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--critical">
                        <FontAwesomeIcon className="notice__icon" fixedWidth icon={faExclamationTriangleSolid} />
                        <p className="caption">
                            {saveProfileStatus.errorMessage &&
                            saveProfileStatus.errorMessage.includes("IncorrectCurrentPassword")
                                ? t("Validation.Password.IncorrectCurrentPassword")
                                : t("Error.ErrorSaving")}
                        </p>
                    </div>
                </div>
            ) : null}
            {!saveProfileStatus.hasError && !saveProfileStatus.isLoading && saveSuccessful ? (
                <div className="dialog__section dialog__section--full dialog__section--noscroll">
                    <div className="notice notice--approve">
                        <FontAwesomeIcon
                            className="notice__icon notice__icon--color-inverted"
                            fixedWidth
                            icon={faCheckSolid}
                        />
                        <p className="caption">{t("Gui.Common.UserProfileSecurityDialog.PasswordSavedSuccessfully")}</p>
                    </div>
                </div>
            ) : null}
            <form data-testid="change-password-tab" className="form__section form__section--full">
                <div className="form__section form__section--full">
                    <div className="form__section form__section--large form__section--column">
                        <div className="form__section form__section--column">
                            <FormControl>
                                <label className="caption caption--small-spacing" htmlFor="newPassword">
                                    {t("Gui.Common.UserProfileSecurityDialog.NewPassword")}
                                </label>
                                <OutlinedInput
                                    inputProps={{ "data-testid": "new-password" }}
                                    id="newPassword"
                                    required
                                    margin="dense"
                                    type={showNewPassword ? "text" : "password"}
                                    autoComplete="new-password"
                                    value={newPassword}
                                    error={newPasswordMatchesCurrentPassword}
                                    onBlur={() => {
                                        setShowNewPasswordValidation(true);
                                    }}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setNewPassword(e.target.value);
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label={t(
                                                    "Gui.Common.UserProfileSecurityDialog.AriaLabelPasswordVisibility",
                                                )}
                                                onClick={handleShowNewPassword}
                                                onMouseDown={handleMouseDownPasswordInput}
                                                edge="end"
                                            >
                                                {showNewPassword ? (
                                                    <FontAwesomeIcon size="sm" icon={faEyeSlashLight} />
                                                ) : (
                                                    <FontAwesomeIcon size="sm" icon={faEyeLight} />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {newPasswordMatchesCurrentPassword ? (
                                    <FormHelperText error>
                                        {t("Validation.UserProfileSecurityDialog.NewAndCurrentPasswordsMatches")}
                                    </FormHelperText>
                                ) : null}
                                {!newPasswordValid && showNewPasswordValidation ? (
                                    <FormHelperText error>
                                        {t("Validation.UserProfileSecurityDialog.NewPassword")}
                                    </FormHelperText>
                                ) : null}
                            </FormControl>
                        </div>
                        <div className="form__section form__section--column">
                            <FormControl>
                                <label className="caption caption--small-spacing" htmlFor="confirmPassword">
                                    {t("Gui.Common.UserProfileSecurityDialog.ConfirmPassword")}
                                </label>
                                <OutlinedInput
                                    inputProps={{ "data-testid": "confirm-password" }}
                                    id="confirmPassword"
                                    required
                                    margin="dense"
                                    type={showNewPassword ? "text" : "password"}
                                    autoComplete="new-password"
                                    value={confirmPassword}
                                    error={!confirmPasswordValid}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setConfirmPassword(e.target.value);
                                    }}
                                    endAdornment={
                                        <InputAdornment position="end">
                                            <IconButton
                                                aria-label={t(
                                                    "Gui.Common.UserProfileSecurityDialog.AriaLabelPasswordVisibility",
                                                )}
                                                onClick={handleShowNewPassword}
                                                onMouseDown={handleMouseDownPasswordInput}
                                                edge="end"
                                            >
                                                {showNewPassword ? (
                                                    <FontAwesomeIcon size="sm" icon={faEyeSlashLight} />
                                                ) : (
                                                    <FontAwesomeIcon size="sm" icon={faEyeLight} />
                                                )}
                                            </IconButton>
                                        </InputAdornment>
                                    }
                                />
                                {!confirmPasswordValid ? (
                                    <FormHelperText error>
                                        {t("Validation.UserProfileSecurityDialog.ConfirmPassword")}
                                    </FormHelperText>
                                ) : null}
                            </FormControl>
                        </div>
                    </div>
                    <div className="form__section form__section--small form__section--column">
                        <div className="data-panels data-panels--rows">
                            <div className="data-panels__panel">
                                <div className="data-panels__heading">
                                    <p className="caption caption--bold">
                                        {t("Validation.UserProfileSecurityDialog.RecommendationsHeader")}
                                    </p>
                                </div>
                                <div className="data-panels__heading">
                                    <p className="caption caption--with-icon">
                                        {passwordValidationIcon(validateNewPasswordAtLeastSevenCharacters(newPassword))}
                                        {t("Validation.UserProfileSecurityDialog.PasswordHintCharacters")}
                                    </p>
                                </div>
                                <div className="data-panels__heading">
                                    <p className="caption caption--with-icon">
                                        {passwordValidationIcon(validateNewPasswordAtLeastOneNumber(newPassword))}
                                        {t("Validation.UserProfileSecurityDialog.PasswordHintNumbers")}
                                    </p>
                                </div>
                                <div className="data-panels__heading">
                                    <p className="caption caption--with-icon">
                                        {passwordValidationIcon(
                                            validateNewPasswordAtLeastOneSpecialCharacter(newPassword),
                                        )}
                                        {t("Validation.UserProfileSecurityDialog.PasswordHintSpecialCharacters")}
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="form__section form__section--alt form__section--full">
                    <div className="form__section form__section--full">
                        <p className="title title--h3">
                            {t("Gui.Common.UserProfileSecurityDialog.ConfirmChangesHeader")}
                        </p>
                        <p className="caption">{t("Gui.Common.UserProfileSecurityDialog.CurrentPasswordNotice")}</p>
                    </div>

                    <div className="form__section form__section--large form__section--column">
                        <FormControl>
                            <label className="caption caption--small-spacing" htmlFor="currentPassword">
                                {t("Gui.Common.UserProfileSecurityDialog.CurrentPassword")}
                            </label>
                            <OutlinedInput
                                inputProps={{ "data-testid": "current-password" }}
                                id="currentPassword"
                                required
                                margin="dense"
                                type={showCurrentPassword ? "text" : "password"}
                                autoComplete="new-password"
                                value={currentPassword}
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setCurrentPassword(e.target.value);
                                }}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton
                                            aria-label={t(
                                                "Gui.Common.UserProfileSecurityDialog.AriaLabelPasswordVisibility",
                                            )}
                                            onClick={handleShowCurrentPassword}
                                            onMouseDown={handleMouseDownPasswordInput}
                                            edge="end"
                                        >
                                            {showCurrentPassword ? (
                                                <FontAwesomeIcon size="sm" icon={faEyeSlashLight} />
                                            ) : (
                                                <FontAwesomeIcon size="sm" icon={faEyeLight} />
                                            )}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                    </div>
                    {showTwoFactorCodeField ? (
                        <div className="form__section form__section--large form__section--column">
                            <FormControl>
                                <label className="caption caption--small-spacing" htmlFor="currentPassword">
                                    {t("Gui.Common.UserProfileSecurityDialog.TwoFactorCode")}
                                </label>
                                <OutlinedInput
                                    inputProps={{ "data-testid": "two-factor-code" }}
                                    id="twoFactorCode"
                                    required
                                    margin="dense"
                                    type="text"
                                    autoComplete="off"
                                    value={twoFactorAuthCode}
                                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                        setTwoFactorAuthCode(e.target.value);
                                    }}
                                />
                            </FormControl>
                        </div>
                    ) : null}
                    <div className="form__section form__section--buttons">
                        <Button
                            disabled={
                                saveProfileStatus.isLoading ||
                                !isDirty ||
                                !currentPassword ||
                                !confirmPasswordValid ||
                                !newPasswordValid ||
                                newPasswordMatchesCurrentPassword
                            }
                            type="button"
                            color="primary"
                            variant="contained"
                            size="small"
                            onClick={saveProfile}
                        >
                            {t("Gui.Buttons.ChangePassword")}
                        </Button>
                    </div>
                </div>
            </form>
        </>
    );
};
