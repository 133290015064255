import React, { FunctionComponent, useState } from "react";
import { useHistory } from "react-router-dom";
import { checkTwoFactorStatus, fetchToken } from "src/services/userProfileService";
import { LoginDetails, LoginRequest } from "src/types";
import { getToken, storeToken } from "src/utils";
import { clearStorage } from "src/utils/storage";
import { AuthStateContext, AuthStateContextType } from "./AuthStateContext";

export const AuthStateContextProvider: FunctionComponent = ({ children }: any) => {
    const [auth, setAuth] = useState<LoginDetails | undefined>(getToken());
    const [authError, setAuthError] = useState(false);
    const [isLoggedIn, setIsLoggedIn] = useState(false);
    const [requiresTwoFactorAuth, setRequiresTwoFactorAuth] = useState(false);
    const [twoFactorStatusError, setTwoFactorError] = useState(false);
    const history = useHistory();

    const logout = () => {
        clearStorage();
        setAuth(undefined);
        setIsLoggedIn(false);
        setRequiresTwoFactorAuth(false);
        history.replace("/login");
    };

    const value: AuthStateContextType = {
        auth,
        authError,
        isLoggedIn,
        requiresTwoFactorAuth,
        twoFactorStatusError,
        checkTwoFactorStatus: async (email: string) => {
            try {
                const twoFactorStatus = await checkTwoFactorStatus(email, logout);
                setRequiresTwoFactorAuth(twoFactorStatus);
            } catch (e) {
                setTwoFactorError(true);
            }
        },
        logout,
        login: async (credentials: LoginRequest) => {
            try {
                const token = await fetchToken(credentials);

                setAuth(token);
                storeToken(token);
                setIsLoggedIn(true);
            } catch (e) {
                setAuthError(true);
            }
        },
    };

    return <AuthStateContext.Provider value={value}>{children}</AuthStateContext.Provider>;
};
