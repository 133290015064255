import { Moment } from "moment-timezone";
import { ScheduleException } from "src/entities";

export const hasDatesInPast = (start: Moment, end: Moment, utcNow: Moment) => {
    return start.isBefore(utcNow) || end.isBefore(utcNow);
};

export const hasInvalidEndDate = (start: Moment, end: Moment) => {
    return end.isSameOrBefore(start);
};

export const hasCollisions = (scheduleExceptions: ScheduleException[], start: Moment, end: Moment) => {
    let ret = false;

    scheduleExceptions?.forEach((e: ScheduleException) => {
        if (start.isSameOrAfter(e.dtStart) && start.isBefore(e.dtStop)) ret = true;
        if (end.isAfter(e.dtStart) && end.isSameOrBefore(e.dtStop)) ret = true;

        if (e.dtStart.isSameOrAfter(start) && e.dtStart.isBefore(end)) ret = true;
        if (e.dtStop.isAfter(start) && e.dtStop.isSameOrBefore(end)) ret = true;
    });

    return ret;
};

export type Duration =
    | "30Minutes"
    | "1Hour"
    | "2Hours"
    | "3Hours"
    | "4Hours"
    | "6Hours"
    | "8Hours"
    | "12Hours"
    | "16Hours"
    | "24Hours";
