import { RouteComponentProps, RouteProps, Route } from "react-router-dom";

import React, { ReactElement } from "react";

// eslint-disable-next-line @typescript-eslint/no-unused-vars
type PropsRouteProps<P extends RouteComponentProps<any>> = RouteProps & {
    component: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>;
    withProps?: any;
};

export const PropsRoute = <P extends RouteComponentProps<any>>({
    component: WrappedComponent,
    withProps,
    ...routeProps
}: PropsRouteProps<P>): ReactElement<P> => {
    return (
        <Route
            {...routeProps}
            render={(childProps) => {
                return <WrappedComponent {...childProps} {...withProps} />;
            }}
        />
    );
};
