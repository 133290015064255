/* eslint-disable @typescript-eslint/unbound-method */
import React, { useState } from "react";
import { Button, TextField, Box, Fade } from "@material-ui/core";
import { useTranslation } from "react-i18next";
import { getUserLastKnownRoute } from "src/utils/storage";
import { useAuth } from "src/contexts/AuthStateContext";
import irisityLogo from "src/img/logos/irisity_logo_header.png";
import { Redirect } from "react-router-dom";
import { Link } from ".";

import "./Login.scss";

export const Login = () => {
    const {
        authError,
        isLoggedIn,
        requiresTwoFactorAuth,
        twoFactorStatusError,
        checkTwoFactorStatus,
        login,
    } = useAuth();
    const { t } = useTranslation();

    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    const [twoFactorAuthCode, setTwoFactorAuthCode] = useState("");

    const submitForm = async (event: React.FormEvent<HTMLFormElement>) => {
        event.preventDefault();
        await login({ username, password, twoFactorAuthCode });
    };

    return isLoggedIn ? (
        <Redirect to={getUserLastKnownRoute() || "/dashboard"} />
    ) : (
        <div className="login-page">
            <div className="login-page__wide-container">
                <div className="login-page__wide-background" />
                <div className="login-page__panel">
                    <form className="login-form" name="loginForm" noValidate={true} onSubmit={submitForm}>
                        <div className="login-form__logo">
                            <img src={irisityLogo} alt="Irisity logo" />
                        </div>
                        <div className="login-form__inputs">
                            <TextField
                                className="login-form__text-field"
                                type="email"
                                value={username}
                                label={t("Gui.Common.Email")}
                                autoFocus={true}
                                onChange={(e) => {
                                    setUsername(e.target.value);
                                    checkTwoFactorStatus(e.target.value);
                                }}
                            />
                            <TextField
                                className="login-form__text-field"
                                type="password"
                                value={password}
                                onChange={(e) => {
                                    setPassword(e.target.value);
                                }}
                                label={t("BeforeLogin.Login.Password")}
                            />
                            {requiresTwoFactorAuth && (
                                <TextField
                                    className="login-form__text-field"
                                    value={twoFactorAuthCode}
                                    onChange={(e) => {
                                        setTwoFactorAuthCode(e.target.value);
                                    }}
                                    label={t("BeforeLogin.Login.GoogleAuthenticationCode")}
                                />
                            )}
                        </div>
                        <div className="form-footer">
                            <div className="btn-group">
                                <Box mt={2}>
                                    <Button type="submit" className="primary">
                                        {t("BeforeLogin.Login.Login")}
                                    </Button>
                                </Box>
                            </div>
                            {authError && (
                                <Fade in={authError}>
                                    <Box mt={1} p={0}>
                                        {t("BeforeLogin.Login.ErrorLoggingIn")}
                                    </Box>
                                </Fade>
                            )}
                            {twoFactorStatusError && (
                                <Fade in={twoFactorStatusError}>
                                    <Box mt={1} p={0}>
                                        {t("BeforeLogin.Login.ErrorLoggingIn.TwoFactorAuthCode")}
                                    </Box>
                                </Fade>
                            )}
                        </div>
                        <div className="login-form__about-link">
                            <Link to="about" className="text-unknown">
                                {t("BeforeLogin.Login.AboutIrisityIRIS")}
                            </Link>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    );
};
